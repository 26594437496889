@import "assets/styles/tokens";
@import "assets/styles/reset";
@import "assets/styles/tailwind";
@import "assets/styles/surface";
@import "assets/styles/primeng";
@import "primeicons/primeicons.css";
@import "assets/styles/general";
@import "assets/styles/layout";
@import "assets/styles/material";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

