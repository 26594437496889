// size & spacing
:root {
  --header-height: 4rem; /* Height of the header */
  --fold-height: calc(100vh - var(--header-height)); /* Height of the fold */
}


//colors
:root {
  color-scheme: dark;

  --color-text-brand: #ae30d3; /* Brand color for text */

  --color-surface-body: #000514; /* Main background color for the body */
  --color-body-gradient-from: #1A1644; /* Gradient start for the body */
  --color-body-gradient-via: #070921; /* Gradient middle for the body */
  --color-body-gradient-to: #000514; /* Gradient end for the body */
  /* Main Surface Colors */
  --color-surface-main: #ffffff1a; /* Darker shade for main surface */
  --color-surface-main-darker: #ffffff0a; /* Even darker for contrast */
  --color-surface-main-lighter: #ffffff2a; /* Lighter purple tint */
  --color-surface-main-text: #e0e0ff; /* Light text for dark backgrounds */

  /* Secondary Surface Colors */
  --color-surface-secondary: #3e3e4e; /* Secondary surface with a purple tint */
  --color-surface-secondary-darker: #343445; /* Darker shade for depth */
  --color-surface-secondary-lighter: #48485a; /* Slightly lighter for differentiation */
  --color-surface-secondary-text: #cacaff; /* Soft light text for readability */
  
  /* Light Surface Colors */
  --color-surface-light: #ffffff; /* Pure white for light surfaces */
  --color-surface-light-text: #000000; /* Black text for light backgrounds */

  /* Dark Surface Colors */
  --color-surface-dark: #000000; /* Pure black for dark surfaces */
  --color-surface-dark-text: #ffffff; /* White text for dark backgrounds */



  /* Additional Palette Colors */
  --color-danger: #ff4d4d; /* Bright red for errors */ 
  --color-danger-text: #000; /* Dark red text for visibility */
  --color-warning: #ffb84d; /* Warm shade for attention */
  --color-warning-text: #663600; /* Bright orange text for visibility */
  --color-success: #33ff99; /* Bright green for success */
  --color-success-text: #004d26; /* Dark green text for visibility */
  --color-info: #3375ff; /* Bright blue for information */
  --color-info-text: #e0eaff; /* Soft blue text for legibility */

  /* Additional UI Colors */
  --color-background: #1e1e2e; /* Deep purple for the background */
  --color-text-primary: #ffffff; /* Pure white for primary text */
  --color-text-secondary: #a1a1b3; /* Greyish purple for secondary text */
  --color-divider: #343445; /* Dark purple for dividers */
}

:root[color-scheme=light] {
  color-scheme: light;
  
  --color-text-brand: #7b21a5; /* Brand color for text */

  --color-surface-body: #fff; /* Main background color for the body */
  --color-body-gradient-from: #fff; /* Gradient start for the body */
  --color-body-gradient-via: #fff; /* Gradient middle for the body */
  --color-body-gradient-to: #fff; /* Gradient end for the body */

  /* Main Surface Colors */
  --color-surface-main: #f9fafb; /* Slate gray for main surface */
  --color-surface-main-darker: #eef0f2; /* Dark slate gray for depth */
  --color-surface-main-lighter: #ffffff; /* Pure white for light surfaces */
  --color-surface-main-text: #000; /* Dark text for light backgrounds */

  /* Secondary Surface Colors */
  --color-surface-secondary: #e6e6f5; /* Soft purple tint for secondary surface */
  --color-surface-secondary-darker: #dcdce8; /* Slightly darker for contrast */
  --color-surface-secondary-lighter: #ececf8; /* Lighter variant for differentiation */
  --color-surface-secondary-text: #3a3a5c; /* Muted dark text for readability */

  /* Light Surface Colors */
  --color-surface-light: #ffffff; /* Pure white for light surfaces */
  --color-surface-light-text: #000000; /* Black text for light backgrounds */

  /* Dark Surface Colors */
  --color-surface-dark: #000; /* Pure black for dark surfaces */
  --color-surface-dark-text: #ffffff; /* White text for dark backgrounds */

  /* Additional Palette Colors */
  --color-danger: #d32f2f; /* Bright red for errors */
  --color-danger-text: #ffffff; /* White text for visibility on red */
  --color-warning: #f9a825; /* Warm yellow for attention */
  --color-warning-text: #4d3600; /* Dark yellow text for contrast */
  --color-success: #2e7d32; /* Deep green for success */
  --color-success-text: #e6ffe6; /* Light green text for readability */
  --color-info: #1565c0; /* Bright blue for information */
  --color-info-text: #e3f2fd; /* Light blue for readability */

  /* Additional UI Colors */
  --color-background: #ffffff; /* Pure white for background */
  --color-text-primary: #1e1e2e; /* Dark purple for primary text */
  --color-text-secondary: #5a5a73; /* Greyish purple for secondary text */
  --color-divider: #e0e0e8; /* Soft grey for dividers */
}
