@import "primeng/resources/primeng.css";
@import "./surface";
@import "./primeng-theme-shared";
@import "./primeng-theme-light";
@import "./primeng-theme-dark";

.p-inputtextarea {
  @apply tw-leading-normal;
}

.p-dialog-mask {
  background-color: rgba(0, 0, 0, 0.46);
  backdrop-filter: blur(3px);
}

.p-dialog-content {
  @apply tw-py-0;
}

.p-button {
  @apply tw-px-4 tw-py-2;
}

p-table {
  thead tr {
    :first-child {
      @apply tw-rounded-ss-xl;
    }

    :last-child {
      @apply tw-rounded-se-xl;
    }

    th {
      padding: .125rem !important;
    }

    &.datatable-th-float-label {
      th {
        padding: 1.5rem .125rem .125rem .125rem !important;
      }
    }


  }

  tr {

    .sticky-col-start,
    .sticky-col-end {
      @apply tw-sticky tw-z-10 surface-secondary-darker tw-opacity-90 tw-shadow-slate-50 tw-backdrop-blur-sm;
    }

    .sticky-col-start {
      @apply tw-start-0;
    }

    .sticky-col-end {
      @apply tw-end-0;
    }

    &.p-highlight {

      .sticky-col-start,
      .sticky-col-end {
        @apply surface-secondary-lighter tw-opacity-100;
      }
    }

  }

  td {
    vertical-align: top;
  }

}

p-chips {
  .p-chips-multiple-container {
    padding: 0.375rem 0.75rem;
  }
}

.uw-card-grid {
  @apply tw-grid;

  >*>p-card {
    @apply tw-block tw-h-full;

    .p-card {
      @apply tw-h-full;

      .p-card-body {
        @apply tw-h-full tw-flex tw-flex-col;

        .p-card-footer {
          @apply tw-mt-auto;
        }
      }
    }
  }
}

.cdk-overlay-container {
  //to fix being hidden behind material dialog
  
  ~ .p-overlay {
    z-index: 10004 !important; 
  }

  ~ .p-overlaypanel, ~ .p-datepicker {
    z-index: 10002 !important; 
  }  

  ~ .p-dialog-mask {
    z-index: 10101 !important; 
  }  
} 