.uw-layout {
  @apply tw-flex tw-h-screen tw-w-screen;

  .uw-layout-header {
    @apply tw-flex tw-items-center tw-gap-2 tw-px-3 surface-main-darker tw-h-[var(--header-height)] tw-sticky tw-top-0 tw-z-20 surface-main-darker;

    &.uw-focus-header {}

    .uw-nav-logo {
      @apply tw-flex tw-items-center tw-justify-center tw-h-full tw-me-auto tw-p-3;

      img {
        @apply tw-h-full tw-max-w-full tw-object-contain
      }
    }
  }

  .mat-drawer-inner-container  {
    @apply  tw-flex tw-flex-col surface-dark lg:surface-main-darker;
  
    nav {
      @apply  tw-pe-2;
      a {
        @apply tw-flex tw-items-center tw-gap-2 tw-p-4  tw-rounded-e-full hover:surface-main-lighter;
  
        &.uw-active {
          @apply tw-font-bold tw-text-lg;
          @extend .surface-main-lighter;
        }
      }
    }
  }
  

  .uw-layout-content {
    @apply tw-flex-1 tw-overflow-y-auto;

  }

}