// from https://tailwindcss.com/docs/preflight#disabling-preflight

body,
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
ul,
figure,
p,
pre {
  margin: 0;
}

html, body {
  height: 100%;
}

a {
  text-decoration: none;
  &:not(.p-button){
    color: inherit;
  }
}

ul {
  padding: 0;
}