// based on primeng "primeng/resources/themes/lara-dark-blue/theme.css"
// :root[color-scheme=dark] {
// dark theme by default
:root {
  :root {
    font-family: "Inter var", sans-serif;
    font-feature-settings: "cv02", "cv03", "cv04", "cv11";
    font-variation-settings: normal;
    --font-family: "Inter var", sans-serif;
    --font-feature-settings: "cv02", "cv03", "cv04", "cv11";
    --surface-a: #1f2937;
    --surface-b: #111827;
    --surface-c: rgba(255, 255, 255, 0.03);
    --surface-d: #424b57;
    --surface-e: #1f2937;
    --surface-f: #1f2937;
    --text-color: rgba(255, 255, 255, 0.87);
    --text-color-secondary: rgba(255, 255, 255, 0.6);
    --primary-color: #60a5fa;
    --primary-color-text: #030712;
    --surface-0: #111827;
    --surface-50: #1f2937;
    --surface-100: #374151;
    --surface-200: #4b5563;
    --surface-300: #6b7280;
    --surface-400: #9ca3af;
    --surface-500: #d1d5db;
    --surface-600: #e5e7eb;
    --surface-700: #f3f4f6;
    --surface-800: #f9fafb;
    --surface-900: #ffffff;
    --gray-50: #f9fafb;
    --gray-100: #f3f4f6;
    --gray-200: #e5e7eb;
    --gray-300: #d1d5db;
    --gray-400: #9ca3af;
    --gray-500: #6b7280;
    --gray-600: #4b5563;
    --gray-700: #374151;
    --gray-800: #1f2937;
    --gray-900: #111827;
    --content-padding: 1.25rem;
    --inline-spacing: 0.5rem;
    --border-radius: 6px;
    --surface-ground: #111827;
    --surface-section: #111827;
    --surface-card: #1f2937;
    --surface-overlay: #1f2937;
    --surface-border: rgba(255, 255, 255, 0.1);
    --surface-hover: rgba(255, 255, 255, 0.03);
    --focus-ring: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    --maskbg: rgba(0, 0, 0, 0.4);
    --highlight-bg: rgba(96, 165, 250, 0.16);
    --highlight-text-color: rgba(255, 255, 255, 0.87);
    color-scheme: dark;
  }

  .p-editor-container .p-editor-toolbar {
    background: #1f2937;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }

  .p-editor-container .p-editor-toolbar.ql-snow {
    border: 1px solid #424b57;
  }

  .p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
    stroke: rgba(255, 255, 255, 0.6);
  }

  .p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
    fill: rgba(255, 255, 255, 0.6);
  }

  .p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
    border: 0 none;
    color: rgba(255, 255, 255, 0.6);
  }

  .p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
    color: rgba(255, 255, 255, 0.87);
  }

  .p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
    stroke: rgba(255, 255, 255, 0.87);
  }

  .p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
    fill: rgba(255, 255, 255, 0.87);
  }

  .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    color: rgba(255, 255, 255, 0.87);
  }

  .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
    stroke: rgba(255, 255, 255, 0.87);
  }

  .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
    fill: rgba(255, 255, 255, 0.87);
  }

  .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    background: #1f2937;
    border: 1px solid #424b57;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    padding: 0.75rem 0;
  }

  .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
    color: rgba(255, 255, 255, 0.87);
  }

  .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
    color: rgba(255, 255, 255, 0.87);
    background: rgba(255, 255, 255, 0.03);
  }

  .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded:not(.ql-icon-picker) .ql-picker-item {
    padding: 0.75rem 1.25rem;
  }

  .p-editor-container .p-editor-content {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .p-editor-container .p-editor-content.ql-snow {
    border: 1px solid #424b57;
  }

  .p-editor-container .p-editor-content .ql-editor {
    background: #111827;
    color: rgba(255, 255, 255, 0.87);
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .p-editor-container .ql-snow.ql-toolbar button:hover,
  .p-editor-container .ql-snow.ql-toolbar button:focus {
    color: rgba(255, 255, 255, 0.87);
  }

  .p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
  .p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
    stroke: rgba(255, 255, 255, 0.87);
  }

  .p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
  .p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
    fill: rgba(255, 255, 255, 0.87);
  }

  .p-editor-container .ql-snow.ql-toolbar button.ql-active,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
    color: #60a5fa;
  }

  .p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
    stroke: #60a5fa;
  }

  .p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
    fill: #60a5fa;
  }

  .p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
    color: #60a5fa;
  }

  @layer primeng {
    * {
      box-sizing: border-box;
    }

    .p-component {
      font-family: var(--font-family);
      font-feature-settings: var(--font-feature-settings, normal);
      font-size: 1rem;
      font-weight: normal;
    }

    .p-component-overlay {
      background-color: rgba(0, 0, 0, 0.4);
      transition-duration: 0.2s;
    }

    .p-disabled,
    .p-component:disabled {
      opacity: 0.4;
    }

    .p-error {
      color: #fca5a5;
    }

    .p-text-secondary {
      color: rgba(255, 255, 255, 0.6);
    }

    .pi {
      font-size: 1rem;
    }

    .p-icon {
      width: 1rem;
      height: 1rem;
    }

    .p-link {
      font-family: var(--font-family);
      font-feature-settings: var(--font-feature-settings, normal);
      font-size: 1rem;
      border-radius: 6px;
    }

    .p-link:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-component-overlay-enter {
      animation: p-component-overlay-enter-animation 150ms forwards;
    }

    .p-component-overlay-leave {
      animation: p-component-overlay-leave-animation 150ms forwards;
    }

    @keyframes p-component-overlay-enter-animation {
      from {
        background-color: transparent;
      }

      to {
        background-color: var(--maskbg);
      }
    }

    @keyframes p-component-overlay-leave-animation {
      from {
        background-color: var(--maskbg);
      }

      to {
        background-color: transparent;
      }
    }

    .p-autocomplete .p-autocomplete-loader {
      right: 0.75rem;
    }

    .p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
      right: 3.75rem;
    }

    .p-autocomplete:not(.p-disabled):hover .p-autocomplete-multiple-container {
      border-color: #60a5fa;
    }

    .p-autocomplete:not(.p-disabled).p-focus .p-autocomplete-multiple-container {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
      border-color: #60a5fa;
    }

    .p-autocomplete .p-autocomplete-multiple-container {
      padding: 0.375rem 0.75rem;
      gap: 0.5rem;
    }

    .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
      padding: 0.375rem 0;
    }

    .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
      font-family: var(--font-family);
      font-feature-settings: var(--font-feature-settings, normal);
      font-size: 1rem;
      color: rgba(255, 255, 255, 0.87);
      padding: 0;
      margin: 0;
    }

    .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
      padding: 0.375rem 0.75rem;
      background: #424b57;
      color: rgba(255, 255, 255, 0.87);
      border-radius: 16px;
    }

    .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
      margin-left: 0.5rem;
    }

    .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token.p-focus {
      background: #6b7280;
      color: rgba(255, 255, 255, 0.87);
    }

    .p-autocomplete.p-invalid.p-component>.p-inputtext {
      border-color: #fca5a5;
    }

    .p-autocomplete-panel {
      background: #1f2937;
      color: rgba(255, 255, 255, 0.87);
      border: 1px solid #424b57;
      border-radius: 6px;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    }

    .p-autocomplete-panel .p-autocomplete-items {
      padding: 0.75rem 0;
    }

    .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
      margin: 0;
      padding: 0.75rem 1.25rem;
      border: 0 none;
      color: rgba(255, 255, 255, 0.87);
      background: transparent;
      transition: box-shadow 0.2s;
      border-radius: 0;
    }

    .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:first-child {
      margin-top: 0;
    }

    .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(96, 165, 250, 0.16);
    }

    .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight.p-focus {
      background: rgba(96, 165, 250, 0.24);
    }

    .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:not(.p-highlight):not(.p-disabled).p-focus {
      color: rgba(255, 255, 255, 0.87);
      background: #424b57;
    }

    .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:not(.p-highlight):not(.p-disabled):hover {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(255, 255, 255, 0.03);
    }

    .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
      margin: 0;
      padding: 0.75rem 1.25rem;
      color: rgba(255, 255, 255, 0.87);
      background: #374151;
      font-weight: 700;
    }

    .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-empty-message {
      padding: 0.75rem 1.25rem;
      color: rgba(255, 255, 255, 0.87);
      background: transparent;
    }

    p-autocomplete.ng-dirty.ng-invalid>.p-autocomplete>.p-inputtext {
      border-color: #fca5a5;
    }

    p-autocomplete.p-autocomplete-clearable .p-inputtext {
      padding-right: 2.5rem;
    }

    p-autocomplete.p-autocomplete-clearable .p-autocomplete-clear-icon {
      color: rgba(255, 255, 255, 0.6);
      right: 0.75rem;
    }

    p-autocomplete.p-autocomplete-clearable .p-autocomplete-dd .p-autocomplete-clear-icon {
      color: rgba(255, 255, 255, 0.6);
      right: 3.75rem;
    }

    p-calendar.ng-dirty.ng-invalid>.p-calendar>.p-inputtext {
      border-color: #fca5a5;
    }

    .p-calendar:not(.p-calendar-disabled).p-focus>.p-inputtext {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
      border-color: #60a5fa;
    }

    .p-datepicker {
      padding: 0.5rem;
      background: #1f2937;
      color: rgba(255, 255, 255, 0.87);
      border: 1px solid #424b57;
      border-radius: 6px;
    }

    .p-datepicker:not(.p-datepicker-inline) {
      background: #1f2937;
      border: 1px solid #424b57;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    }

    .p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
      background: #1f2937;
    }

    .p-datepicker .p-datepicker-header {
      padding: 0.5rem;
      color: rgba(255, 255, 255, 0.87);
      background: #1f2937;
      font-weight: 700;
      margin: 0;
      border-bottom: 1px solid #424b57;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
    }

    .p-datepicker .p-datepicker-header .p-datepicker-prev,
    .p-datepicker .p-datepicker-header .p-datepicker-next {
      width: 2rem;
      height: 2rem;
      color: rgba(255, 255, 255, 0.6);
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

    .p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
    .p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
      color: rgba(255, 255, 255, 0.87);
      border-color: transparent;
      background: rgba(255, 255, 255, 0.03);
    }

    .p-datepicker .p-datepicker-header .p-datepicker-prev:focus-visible,
    .p-datepicker .p-datepicker-header .p-datepicker-next:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-datepicker .p-datepicker-header .p-datepicker-title {
      line-height: 2rem;
    }

    .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
    .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
      color: rgba(255, 255, 255, 0.87);
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      font-weight: 700;
      padding: 0.5rem;
    }

    .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,
    .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
      color: #60a5fa;
    }

    .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
      margin-right: 0.5rem;
    }

    .p-datepicker table {
      font-size: 1rem;
      margin: 0.5rem 0;
    }

    .p-datepicker table th {
      padding: 0.5rem;
    }

    .p-datepicker table th>span {
      width: 2.5rem;
      height: 2.5rem;
    }

    .p-datepicker table td {
      padding: 0.5rem;
    }

    .p-datepicker table td>span {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      transition: box-shadow 0.2s;
      border: 1px solid transparent;
    }

    .p-datepicker table td>span.p-highlight {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(96, 165, 250, 0.16);
    }

    .p-datepicker table td>span:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-datepicker table td.p-datepicker-today>span {
      background: transparent;
      color: #60a5fa;
      border-color: transparent;
    }

    .p-datepicker table td.p-datepicker-today>span.p-highlight {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(96, 165, 250, 0.16);
    }

    .p-datepicker .p-datepicker-buttonbar {
      padding: 1rem 0;
      border-top: 1px solid #424b57;
    }

    .p-datepicker .p-datepicker-buttonbar .p-button {
      width: auto;
    }

    .p-datepicker .p-timepicker {
      border-top: 1px solid #424b57;
      padding: 0.5rem;
    }

    .p-datepicker .p-timepicker button {
      width: 2rem;
      height: 2rem;
      color: rgba(255, 255, 255, 0.6);
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

    .p-datepicker .p-timepicker button:enabled:hover {
      color: rgba(255, 255, 255, 0.87);
      border-color: transparent;
      background: rgba(255, 255, 255, 0.03);
    }

    .p-datepicker .p-timepicker button:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-datepicker .p-timepicker button:last-child {
      margin-top: 0.2em;
    }

    .p-datepicker .p-timepicker span {
      font-size: 1.25rem;
    }

    .p-datepicker .p-timepicker>div {
      padding: 0 0.5rem;
    }

    .p-datepicker.p-datepicker-timeonly .p-timepicker {
      border-top: 0 none;
    }

    .p-datepicker .p-monthpicker {
      margin: 0.5rem 0;
    }

    .p-datepicker .p-monthpicker .p-monthpicker-month {
      padding: 0.5rem;
      transition: box-shadow 0.2s;
      border-radius: 6px;
    }

    .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(96, 165, 250, 0.16);
    }

    .p-datepicker .p-yearpicker {
      margin: 0.5rem 0;
    }

    .p-datepicker .p-yearpicker .p-yearpicker-year {
      padding: 0.5rem;
      transition: box-shadow 0.2s;
      border-radius: 6px;
    }

    .p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(96, 165, 250, 0.16);
    }

    .p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
      border-left: 1px solid #424b57;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      padding-top: 0;
      padding-bottom: 0;
    }

    .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
      padding-left: 0;
      border-left: 0 none;
    }

    .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
      padding-right: 0;
    }

    .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
      background: rgba(255, 255, 255, 0.03);
    }

    .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
      background: rgba(255, 255, 255, 0.03);
    }

    .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
      background: rgba(255, 255, 255, 0.03);
    }

    .p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    p-calendar.p-calendar-clearable .p-inputtext {
      padding-right: 2.5rem;
    }

    p-calendar.p-calendar-clearable .p-calendar-clear-icon {
      color: rgba(255, 255, 255, 0.6);
      right: 0.75rem;
    }

    p-calendar.p-calendar-clearable .p-calendar-w-btn .p-calendar-clear-icon {
      color: rgba(255, 255, 255, 0.6);
      right: 3.75rem;
    }

    @media screen and (max-width: 769px) {

      .p-datepicker table th,
      .p-datepicker table td {
        padding: 0;
      }
    }

    .p-cascadeselect {
      background: #111827;
      border: 1px solid #424b57;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
      border-radius: 6px;
    }

    .p-cascadeselect:not(.p-disabled):hover {
      border-color: #60a5fa;
    }

    .p-cascadeselect:not(.p-disabled).p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
      border-color: #60a5fa;
    }

    .p-cascadeselect .p-cascadeselect-label {
      background: transparent;
      border: 0 none;
      padding: 0.75rem 0.75rem;
    }

    .p-cascadeselect .p-cascadeselect-label.p-placeholder {
      color: rgba(255, 255, 255, 0.6);
    }

    .p-cascadeselect .p-cascadeselect-label:enabled:focus {
      outline: 0 none;
      box-shadow: none;
    }

    .p-cascadeselect .p-cascadeselect-trigger {
      background: transparent;
      color: rgba(255, 255, 255, 0.6);
      width: 3rem;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    .p-cascadeselect.p-invalid.p-component {
      border-color: #fca5a5;
    }

    .p-cascadeselect.p-variant-filled {
      background-color: #424b57;
    }

    .p-cascadeselect.p-variant-filled:enabled:hover {
      background-color: #424b57;
    }

    .p-cascadeselect.p-variant-filled:enabled:focus {
      background-color: #424b57;
    }

    .p-cascadeselect-panel {
      background: #1f2937;
      color: rgba(255, 255, 255, 0.87);
      border: 1px solid #424b57;
      border-radius: 6px;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    }

    .p-cascadeselect-panel .p-cascadeselect-items {
      padding: 0.75rem 0;
    }

    .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
      margin: 0;
      border: 0 none;
      color: rgba(255, 255, 255, 0.87);
      background: transparent;
      transition: box-shadow 0.2s;
      border-radius: 0;
    }

    .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:first-child {
      margin-top: 0;
    }

    .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(96, 165, 250, 0.16);
    }

    .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight.p-focus {
      background: rgba(96, 165, 250, 0.24);
    }

    .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled).p-focus {
      color: rgba(255, 255, 255, 0.87);
      background: #424b57;
    }

    .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(255, 255, 255, 0.03);
    }

    .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content {
      padding: 0.75rem 1.25rem;
    }

    .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon {
      font-size: 0.875rem;
    }

    .p-input-filled .p-cascadeselect {
      background: #424b57;
    }

    .p-input-filled .p-cascadeselect:not(.p-disabled):hover {
      background-color: #424b57;
    }

    .p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {
      background-color: #424b57;
    }

    p-cascadeselect.ng-dirty.ng-invalid>.p-cascadeselect {
      border-color: #fca5a5;
    }

    p-cascadeselect.p-cascadeselect-clearable .p-cascadeselect-label {
      padding-right: 0.75rem;
    }

    p-cascadeselect.p-cascadeselect-clearable .p-cascadeselect-clear-icon {
      color: rgba(255, 255, 255, 0.6);
      right: 3rem;
    }

    .p-overlay-modal .p-cascadeselect-sublist .p-cascadeselect-panel {
      box-shadow: none;
      border-radius: 0;
      padding: 0.25rem 0 0.25rem 0.5rem;
    }

    .p-overlay-modal .p-cascadeselect-item-active>.p-cascadeselect-item-content .p-cascadeselect-group-icon {
      transform: rotate(90deg);
    }

    .p-checkbox {
      width: 22px;
      height: 22px;
    }

    .p-checkbox .p-checkbox-box {
      border: 2px solid #424b57;
      background: #111827;
      width: 22px;
      height: 22px;
      color: rgba(255, 255, 255, 0.87);
      border-radius: 6px;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
      outline-color: transparent;
    }

    .p-checkbox .p-checkbox-box .p-checkbox-icon {
      transition-duration: 0.2s;
      color: #030712;
      font-size: 14px;
    }

    .p-checkbox .p-checkbox-box .p-icon {
      width: 14px;
      height: 14px;
    }

    .p-checkbox .p-checkbox-box.p-highlight {
      border-color: #60a5fa;
      background: #60a5fa;
    }

    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
      border-color: #60a5fa;
    }

    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
      border-color: #60a5fa;
    }

    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
      border-color: #bfdbfe;
      background: #bfdbfe;
      color: #030712;
    }

    .p-checkbox.p-variant-filled .p-checkbox-box {
      background-color: #424b57;
    }

    .p-checkbox.p-variant-filled .p-checkbox-box.p-highlight {
      background: #60a5fa;
    }

    .p-checkbox.p-variant-filled:not(.p-checkbox-disabled) .p-checkbox-box:hover {
      background-color: #424b57;
    }

    .p-checkbox.p-variant-filled:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
      background: #bfdbfe;
    }

    p-checkbox.ng-dirty.ng-invalid>.p-checkbox>.p-checkbox-box {
      border-color: #fca5a5;
    }

    .p-input-filled .p-checkbox .p-checkbox-box {
      background-color: #424b57;
    }

    .p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
      background: #60a5fa;
    }

    .p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
      background-color: #424b57;
    }

    .p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
      background: #bfdbfe;
    }

    .p-checkbox-label {
      margin-left: 0.5rem;
    }

    p-tristatecheckbox.ng-dirty.ng-invalid>.p-checkbox>.p-checkbox-box {
      border-color: #fca5a5;
    }

    .p-chips:not(.p-disabled):hover .p-chips-multiple-container {
      border-color: #60a5fa;
    }

    .p-chips:not(.p-disabled).p-focus .p-chips-multiple-container {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
      border-color: #60a5fa;
    }

    .p-chips .p-chips-multiple-container {
      padding: 0.375rem 0.75rem;
      gap: 0.5rem;
    }

    .p-chips .p-chips-multiple-container .p-chips-token {
      padding: 0.375rem 0.75rem;
      margin-right: 0.5rem;
      background: #424b57;
      color: rgba(255, 255, 255, 0.87);
      border-radius: 16px;
    }

    .p-chips .p-chips-multiple-container .p-chips-token.p-focus {
      background: #6b7280;
      color: rgba(255, 255, 255, 0.87);
    }

    .p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
      margin-left: 0.5rem;
    }

    .p-chips .p-chips-multiple-container .p-chips-input-token {
      padding: 0.375rem 0;
    }

    .p-chips .p-chips-multiple-container .p-chips-input-token input {
      font-family: var(--font-family);
      font-feature-settings: var(--font-feature-settings, normal);
      font-size: 1rem;
      color: rgba(255, 255, 255, 0.87);
      padding: 0;
      margin: 0;
    }

    p-chips.ng-dirty.ng-invalid>.p-chips>.p-inputtext {
      border-color: #fca5a5;
    }

    p-chips.p-chips-clearable .p-inputtext {
      padding-right: 1.75rem;
    }

    p-chips.p-chips-clearable .p-chips-clear-icon {
      color: rgba(255, 255, 255, 0.6);
      right: 0.75rem;
    }

    .p-colorpicker-preview,
    .p-fluid .p-colorpicker-preview.p-inputtext {
      width: 2rem;
      height: 2rem;
    }

    .p-colorpicker-panel {
      background: #1f2937;
      border: 1px solid #424b57;
    }

    .p-colorpicker-panel .p-colorpicker-color-handle,
    .p-colorpicker-panel .p-colorpicker-hue-handle {
      border-color: rgba(255, 255, 255, 0.87);
    }

    .p-colorpicker-overlay-panel {
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    }

    .p-dropdown {
      background: #111827;
      border: 1px solid #424b57;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
      border-radius: 6px;
    }

    .p-dropdown:not(.p-disabled):hover {
      border-color: #60a5fa;
    }

    .p-dropdown:not(.p-disabled).p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
      border-color: #60a5fa;
    }

    .p-dropdown.p-dropdown-clearable .p-dropdown-label {
      padding-right: 1.75rem;
    }

    .p-dropdown .p-dropdown-label {
      background: transparent;
      border: 0 none;
    }

    .p-dropdown .p-dropdown-label.p-placeholder {
      color: rgba(255, 255, 255, 0.6);
    }

    .p-dropdown .p-dropdown-label:focus,
    .p-dropdown .p-dropdown-label:enabled:focus {
      outline: 0 none;
      box-shadow: none;
    }

    .p-dropdown .p-dropdown-trigger {
      background: transparent;
      color: rgba(255, 255, 255, 0.6);
      width: 3rem;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    .p-dropdown .p-dropdown-clear-icon {
      color: rgba(255, 255, 255, 0.6);
      right: 3rem;
    }

    .p-dropdown.p-invalid.p-component {
      border-color: #fca5a5;
    }

    .p-dropdown.p-variant-filled {
      background-color: #424b57;
    }

    .p-dropdown.p-variant-filled:enabled:hover {
      background-color: #424b57;
    }

    .p-dropdown.p-variant-filled:enabled:focus {
      background-color: #424b57;
    }

    .p-dropdown-panel {
      background: #1f2937;
      color: rgba(255, 255, 255, 0.87);
      border: 1px solid #424b57;
      border-radius: 6px;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    }

    .p-dropdown-panel .p-dropdown-header {
      padding: 0.75rem 1.25rem;
      border-bottom: 1px solid #424b57;
      color: rgba(255, 255, 255, 0.87);
      background: #1f2937;
      margin: 0;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
    }

    .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
      padding-right: 1.75rem;
      margin-right: -1.75rem;
    }

    .p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
      right: 0.75rem;
      color: rgba(255, 255, 255, 0.6);
    }

    .p-dropdown-panel .p-dropdown-items {
      padding: 0.75rem 0;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
      margin: 0;
      padding: 0.75rem 1.25rem;
      border: 0 none;
      color: rgba(255, 255, 255, 0.87);
      background: transparent;
      transition: box-shadow 0.2s;
      border-radius: 0;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item:first-child {
      margin-top: 0;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(96, 165, 250, 0.16);
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
      background: rgba(96, 165, 250, 0.24);
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
      color: rgba(255, 255, 255, 0.87);
      background: #424b57;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(255, 255, 255, 0.03);
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
      margin: 0;
      padding: 0.75rem 1.25rem;
      color: rgba(255, 255, 255, 0.87);
      background: #374151;
      font-weight: 700;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
      padding: 0.75rem 1.25rem;
      color: rgba(255, 255, 255, 0.87);
      background: transparent;
    }

    .p-input-filled .p-dropdown {
      background: #424b57;
    }

    .p-input-filled .p-dropdown:not(.p-disabled):hover {
      background-color: #424b57;
    }

    .p-input-filled .p-dropdown:not(.p-disabled).p-focus {
      background-color: #424b57;
    }

    .p-input-filled .p-dropdown:not(.p-disabled).p-focus .p-inputtext {
      background-color: transparent;
    }

    p-dropdown.ng-dirty.ng-invalid>.p-dropdown {
      border-color: #fca5a5;
    }

    .p-icon-field .p-input-icon {
      position: absolute;
      top: 50%;
      margin-top: -0.5rem;
    }

    .p-inputgroup-addon {
      background: #1f2937;
      color: rgba(255, 255, 255, 0.6);
      border-top: 1px solid #424b57;
      border-left: 1px solid #424b57;
      border-bottom: 1px solid #424b57;
      padding: 0.75rem 0.75rem;
      min-width: 3rem;
    }

    .p-inputgroup-addon:last-child {
      border-right: 1px solid #424b57;
    }

    .p-inputgroup>.p-component,
    .p-inputgroup>.p-inputwrapper>.p-inputtext,
    .p-inputgroup>.p-float-label>.p-component {
      border-radius: 0;
      margin: 0;
    }

    .p-inputgroup>.p-component+.p-inputgroup-addon,
    .p-inputgroup>.p-inputwrapper>.p-inputtext+.p-inputgroup-addon,
    .p-inputgroup>.p-float-label>.p-component+.p-inputgroup-addon {
      border-left: 0 none;
    }

    .p-inputgroup>.p-component:focus,
    .p-inputgroup>.p-inputwrapper>.p-inputtext:focus,
    .p-inputgroup>.p-float-label>.p-component:focus {
      z-index: 1;
    }

    .p-inputgroup>.p-component:focus~label,
    .p-inputgroup>.p-inputwrapper>.p-inputtext:focus~label,
    .p-inputgroup>.p-float-label>.p-component:focus~label {
      z-index: 1;
    }

    .p-inputgroup-addon:first-child,
    .p-inputgroup button:first-child,
    .p-inputgroup input:first-child,
    .p-inputgroup>.p-inputwrapper:first-child>.p-component,
    .p-inputgroup>.p-inputwrapper:first-child>.p-component>.p-inputtext {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    .p-inputgroup .p-float-label:first-child input {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    .p-inputgroup-addon:last-child,
    .p-inputgroup button:last-child,
    .p-inputgroup input:last-child,
    .p-inputgroup>.p-inputwrapper:last-child>.p-component,
    .p-inputgroup>.p-inputwrapper:last-child>.p-component>.p-inputtext {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    .p-inputgroup .p-float-label:last-child input {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    .p-fluid .p-inputgroup .p-button {
      width: auto;
    }

    .p-fluid .p-inputgroup .p-button.p-button-icon-only {
      width: 3rem;
    }

    .p-icon-field-left .p-input-icon:first-of-type {
      left: 0.75rem;
      color: rgba(255, 255, 255, 0.6);
    }

    .p-icon-field-right .p-input-icon:last-of-type {
      right: 0.75rem;
      color: rgba(255, 255, 255, 0.6);
    }

    p-inputmask.ng-dirty.ng-invalid>.p-inputtext {
      border-color: #fca5a5;
    }

    p-inputmask.p-inputmask-clearable .p-inputtext {
      padding-right: 2.5rem;
    }

    p-inputmask.p-inputmask-clearable .p-inputmask-clear-icon {
      color: rgba(255, 255, 255, 0.6);
      right: 0.75rem;
    }

    .p-inputmask.p-variant-filled {
      background-color: #424b57;
    }

    .p-inputmask.p-variant-filled:enabled:hover {
      background-color: #424b57;
    }

    .p-inputmask.p-variant-filled:enabled:focus {
      background-color: #424b57;
    }

    p-inputnumber.ng-dirty.ng-invalid>.p-inputnumber>.p-inputtext {
      border-color: #fca5a5;
    }

    p-inputnumber.p-inputnumber-clearable .p-inputnumber-input {
      padding-right: 2.5rem;
    }

    p-inputnumber.p-inputnumber-clearable .p-inputnumber-clear-icon {
      color: rgba(255, 255, 255, 0.6);
      right: 0.75rem;
    }

    p-inputnumber.p-inputnumber-clearable .p-inputnumber-buttons-stacked .p-inputnumber-clear-icon {
      right: 3.75rem;
    }

    p-inputnumber.p-inputnumber-clearable .p-inputnumber-buttons-horizontal .p-inputnumber-clear-icon {
      right: 3.75rem;
    }

    p-inputnumber.p-inputnumber.p-variant-filled>.p-inputnumber-input {
      background-color: #424b57;
    }

    p-inputnumber.p-inputnumber.p-variant-filled>.p-inputnumber-input:enabled:hover {
      background-color: #424b57;
    }

    p-inputnumber.p-inputnumber.p-variant-filled>.p-inputnumber-input:enabled:focus {
      background-color: #424b57;
    }

    .p-inputotp {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    .p-inputotp-input {
      text-align: center;
      width: 2.5rem;
    }

    .p-inputswitch {
      width: 3rem;
      height: 1.75rem;
    }

    .p-inputswitch .p-inputswitch-slider {
      background: #6b7280;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
      border-radius: 30px;
    }

    .p-inputswitch .p-inputswitch-slider:before {
      background: rgba(255, 255, 255, 0.6);
      width: 1.25rem;
      height: 1.25rem;
      left: 0.25rem;
      margin-top: -0.625rem;
      border-radius: 50%;
      transition-duration: 0.2s;
    }

    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
      transform: translateX(1.25rem);
    }

    .p-inputswitch.p-focus .p-inputswitch-slider {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
      background: #424b57;
    }

    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
      background: #60a5fa;
    }

    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
      background: #030712;
    }

    .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
      background: #93c5fd;
    }

    p-inputswitch.ng-dirty.ng-invalid>.p-inputswitch>.p-inputswitch-slider {
      border-color: #fca5a5;
    }

    .p-inputtext {
      font-family: var(--font-family);
      font-feature-settings: var(--font-feature-settings, normal);
      font-size: 1rem;
      color: rgba(255, 255, 255, 0.87);
      background: #111827;
      padding: 0.75rem 0.75rem;
      border: 1px solid #424b57;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
      appearance: none;
      border-radius: 6px;
    }

    .p-inputtext:enabled:hover {
      border-color: #60a5fa;
    }

    .p-inputtext:enabled:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
      border-color: #60a5fa;
    }

    .p-inputtext.ng-dirty.ng-invalid {
      border-color: #fca5a5;
    }

    .p-inputtext.p-variant-filled {
      background-color: #424b57;
    }

    .p-inputtext.p-variant-filled:enabled:hover {
      background-color: #424b57;
    }

    .p-inputtext.p-variant-filled:enabled:focus {
      background-color: #424b57;
    }

    .p-inputtext.p-inputtext-sm {
      font-size: 0.875rem;
      padding: 0.65625rem 0.65625rem;
    }

    .p-inputtext.p-inputtext-lg {
      font-size: 1.25rem;
      padding: 0.9375rem 0.9375rem;
    }

    .p-float-label>label {
      left: 0.75rem;
      color: rgba(255, 255, 255, 0.6);
      transition-duration: 0.2s;
    }

    .p-float-label>.ng-invalid.ng-dirty+label {
      color: #fca5a5;
    }

    .p-input-icon-left>.p-icon-wrapper.p-icon,
    .p-input-icon-left>i:first-of-type {
      left: 0.75rem;
      color: rgba(255, 255, 255, 0.6);
    }

    .p-input-icon-left>.p-inputtext {
      padding-left: 2.5rem;
    }

    .p-input-icon-left.p-float-label>label {
      left: 2.5rem;
    }

    .p-input-icon-right>.p-icon-wrapper,
    .p-input-icon-right>i:last-of-type {
      right: 0.75rem;
      color: rgba(255, 255, 255, 0.6);
    }

    .p-input-icon-right>.p-inputtext {
      padding-right: 2.5rem;
    }

    .p-icon-field-left>.p-inputtext {
      padding-left: 2.5rem;
    }

    .p-icon-field-left.p-float-label>label {
      left: 2.5rem;
    }

    .p-icon-field-right>.p-inputtext {
      padding-right: 2.5rem;
    }

    ::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.6);
    }

    :-moz-placeholder {
      color: rgba(255, 255, 255, 0.6);
    }

    ::-moz-placeholder {
      color: rgba(255, 255, 255, 0.6);
    }

    :-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.6);
    }

    .p-input-filled .p-inputtext {
      background-color: #424b57;
    }

    .p-input-filled .p-inputtext:enabled:hover {
      background-color: #424b57;
    }

    .p-input-filled .p-inputtext:enabled:focus {
      background-color: #424b57;
    }

    .p-inputtext-sm .p-inputtext {
      font-size: 0.875rem;
      padding: 0.65625rem 0.65625rem;
    }

    .p-inputtext-lg .p-inputtext {
      font-size: 1.25rem;
      padding: 0.9375rem 0.9375rem;
    }

    .p-listbox {
      background: #1f2937;
      color: rgba(255, 255, 255, 0.87);
      border: 1px solid #424b57;
      border-radius: 6px;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    }

    .p-listbox .p-listbox-header {
      padding: 0.75rem 1.25rem;
      border-bottom: 1px solid #424b57;
      color: rgba(255, 255, 255, 0.87);
      background: #1f2937;
      margin: 0;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
    }

    .p-listbox .p-listbox-header .p-listbox-filter {
      padding-right: 1.75rem;
    }

    .p-listbox .p-listbox-header .p-listbox-filter-icon {
      right: 0.75rem;
      color: rgba(255, 255, 255, 0.6);
    }

    .p-listbox .p-listbox-header .p-checkbox {
      margin-right: 0.5rem;
    }

    .p-listbox .p-listbox-list {
      padding: 0.75rem 0;
      outline: 0 none;
    }

    .p-listbox .p-listbox-list .p-listbox-item {
      margin: 0;
      padding: 0.75rem 1.25rem;
      border: 0 none;
      color: rgba(255, 255, 255, 0.87);
      transition: box-shadow 0.2s;
      border-radius: 0;
    }

    .p-listbox .p-listbox-list .p-listbox-item:first-child {
      margin-top: 0;
    }

    .p-listbox .p-listbox-list .p-listbox-item.p-highlight {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(96, 165, 250, 0.16);
    }

    .p-listbox .p-listbox-list .p-listbox-item .p-checkbox {
      margin-right: 0.5rem;
    }

    .p-listbox .p-listbox-list .p-listbox-item-group {
      margin: 0;
      padding: 0.75rem 1.25rem;
      color: rgba(255, 255, 255, 0.87);
      background: #374151;
      font-weight: 700;
    }

    .p-listbox .p-listbox-list .p-listbox-empty-message {
      padding: 0.75rem 1.25rem;
      color: rgba(255, 255, 255, 0.87);
      background: transparent;
    }

    .p-listbox:not(.p-disabled) .p-listbox-item.p-highlight.p-focus {
      background: rgba(96, 165, 250, 0.24);
    }

    .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled).p-focus {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(255, 255, 255, 0.03);
    }

    .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(255, 255, 255, 0.03);
    }

    .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover.p-focus {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(255, 255, 255, 0.03);
    }

    .p-listbox.p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
      border-color: #60a5fa;
    }

    p-listbox.ng-dirty.ng-invalid>.p-listbox {
      border-color: #fca5a5;
    }

    .p-multiselect {
      background: #111827;
      border: 1px solid #424b57;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
      border-radius: 6px;
    }

    .p-multiselect:not(.p-disabled):hover {
      border-color: #60a5fa;
    }

    .p-multiselect:not(.p-disabled).p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
      border-color: #60a5fa;
    }

    .p-multiselect .p-multiselect-label {
      padding: 0.75rem 0.75rem;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    }

    .p-multiselect .p-multiselect-label.p-placeholder {
      color: rgba(255, 255, 255, 0.6);
    }

    .p-multiselect.p-multiselect-chip .p-multiselect-token {
      padding: 0.375rem 0.75rem;
      margin-right: 0.5rem;
      background: #424b57;
      color: rgba(255, 255, 255, 0.87);
      border-radius: 16px;
    }

    .p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
      margin-left: 0.5rem;
    }

    .p-multiselect .p-multiselect-trigger {
      background: transparent;
      color: rgba(255, 255, 255, 0.6);
      width: 3rem;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    .p-multiselect.p-variant-filled {
      background: #424b57;
    }

    .p-multiselect.p-variant-filled:not(.p-disabled):hover {
      background-color: #424b57;
    }

    .p-multiselect.p-variant-filled:not(.p-disabled).p-focus {
      background-color: #424b57;
    }

    .p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label {
      padding: 0.375rem 0.75rem;
    }

    .p-multiselect-clearable .p-multiselect-label-container {
      padding-right: 1.75rem;
    }

    .p-multiselect-clearable .p-multiselect-clear-icon {
      color: rgba(255, 255, 255, 0.6);
      right: 3rem;
    }

    .p-multiselect-panel {
      background: #1f2937;
      color: rgba(255, 255, 255, 0.87);
      border: 1px solid #424b57;
      border-radius: 6px;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    }

    .p-multiselect-panel .p-multiselect-header {
      padding: 0.75rem 1.25rem;
      border-bottom: 1px solid #424b57;
      color: rgba(255, 255, 255, 0.87);
      background: #1f2937;
      margin: 0;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
    }

    .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
      padding-right: 1.75rem;
    }

    .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
      right: 0.75rem;
      color: rgba(255, 255, 255, 0.6);
    }

    .p-multiselect-panel .p-multiselect-header .p-checkbox {
      margin-right: 0.5rem;
    }

    .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
      margin-left: 0.5rem;
      width: 2rem;
      height: 2rem;
      color: rgba(255, 255, 255, 0.6);
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

    .p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
      color: rgba(255, 255, 255, 0.87);
      border-color: transparent;
      background: rgba(255, 255, 255, 0.03);
    }

    .p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-multiselect-panel .p-multiselect-items {
      padding: 0.75rem 0;
    }

    .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
      margin: 0;
      padding: 0.75rem 1.25rem;
      border: 0 none;
      color: rgba(255, 255, 255, 0.87);
      background: transparent;
      transition: box-shadow 0.2s;
      border-radius: 0;
    }

    .p-multiselect-panel .p-multiselect-items .p-multiselect-item:first-child {
      margin-top: 0;
    }

    .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(96, 165, 250, 0.16);
    }

    .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight.p-focus {
      background: rgba(96, 165, 250, 0.24);
    }

    .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled).p-focus {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(255, 255, 255, 0.03);
    }

    .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(255, 255, 255, 0.03);
    }

    .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
      margin-right: 0.5rem;
    }

    .p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
      margin: 0;
      padding: 0.75rem 1.25rem;
      color: rgba(255, 255, 255, 0.87);
      background: #374151;
      font-weight: 700;
    }

    .p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
      padding: 0.75rem 1.25rem;
      color: rgba(255, 255, 255, 0.87);
      background: transparent;
    }

    .p-input-filled .p-multiselect {
      background: #424b57;
    }

    .p-input-filled .p-multiselect:not(.p-disabled):hover {
      background-color: #424b57;
    }

    .p-input-filled .p-multiselect:not(.p-disabled).p-focus {
      background-color: #424b57;
    }

    p-multiselect.ng-dirty.ng-invalid>.p-multiselect {
      border-color: #fca5a5;
    }

    p-password.ng-invalid.ng-dirty>.p-password>.p-inputtext {
      border-color: #fca5a5;
    }

    .p-password-panel {
      padding: 1.25rem;
      background: #1f2937;
      color: rgba(255, 255, 255, 0.87);
      border: 1px solid #424b57;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
      border-radius: 6px;
    }

    .p-password-panel .p-password-meter {
      margin-bottom: 0.5rem;
      background: #424b57;
    }

    .p-password-panel .p-password-meter .p-password-strength.weak {
      background: #eb9a9c;
    }

    .p-password-panel .p-password-meter .p-password-strength.medium {
      background: #ffcf91;
    }

    .p-password-panel .p-password-meter .p-password-strength.strong {
      background: #93deac;
    }

    p-password.p-password-clearable .p-password-input {
      padding-right: 2.5rem;
    }

    p-password.p-password-clearable .p-password-clear-icon {
      color: rgba(255, 255, 255, 0.6);
      right: 0.75rem;
    }

    p-password.p-password-clearable.p-password-mask .p-password-input {
      padding-right: 4.25rem;
    }

    p-password.p-password-clearable.p-password-mask .p-password-clear-icon {
      color: rgba(255, 255, 255, 0.6);
      right: 2.5rem;
    }

    .p-radiobutton {
      width: 22px;
      height: 22px;
    }

    .p-radiobutton .p-radiobutton-box {
      border: 2px solid #424b57;
      background: #111827;
      width: 22px;
      height: 22px;
      color: rgba(255, 255, 255, 0.87);
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
      outline-color: transparent;
    }

    .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
      border-color: #60a5fa;
    }

    .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
      border-color: #60a5fa;
    }

    .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
      width: 12px;
      height: 12px;
      transition-duration: 0.2s;
      background-color: #030712;
    }

    .p-radiobutton .p-radiobutton-box.p-highlight {
      border-color: #60a5fa;
      background: #60a5fa;
    }

    .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
      border-color: #bfdbfe;
      background: #bfdbfe;
      color: #030712;
    }

    .p-radiobutton.p-variant-filled .p-radiobutton-box {
      background-color: #424b57;
    }

    .p-radiobutton.p-variant-filled .p-radiobutton-box:not(.p-disabled):hover {
      background-color: #424b57;
    }

    .p-radiobutton.p-variant-filled .p-radiobutton-box.p-highlight {
      background: #60a5fa;
    }

    .p-radiobutton.p-variant-filled .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
      background: #bfdbfe;
    }

    p-radiobutton.ng-dirty.ng-invalid>.p-radiobutton>.p-radiobutton-box {
      border-color: #fca5a5;
    }

    .p-input-filled .p-radiobutton .p-radiobutton-box {
      background-color: #424b57;
    }

    .p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
      background-color: #424b57;
    }

    .p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
      background: #60a5fa;
    }

    .p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
      background: #bfdbfe;
    }

    .p-radiobutton-label {
      margin-left: 0.5rem;
    }

    .p-rating {
      gap: 0.5rem;
    }

    .p-rating .p-rating-item {
      border-radius: 50%;
      outline-color: transparent;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }

    .p-rating .p-rating-item .p-rating-icon {
      color: rgba(255, 255, 255, 0.87);
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
      font-size: 1.143rem;
    }

    .p-rating .p-rating-item .p-rating-icon.p-icon {
      width: 1.143rem;
      height: 1.143rem;
    }

    .p-rating .p-rating-item .p-rating-icon.p-rating-cancel {
      color: #f48fb1;
    }

    .p-rating .p-rating-item.p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-rating .p-rating-item.p-rating-item-active .p-rating-icon {
      color: #60a5fa;
    }

    .p-rating:not(.p-disabled):not(.p-readonly) .p-rating-item:hover .p-rating-icon {
      color: #60a5fa;
    }

    .p-rating:not(.p-disabled):not(.p-readonly) .p-rating-item:hover .p-rating-icon.p-rating-cancel {
      color: #f48fb1;
    }

    .p-selectbutton .p-button {
      background: #1f2937;
      border: 1px solid #424b57;
      color: rgba(255, 255, 255, 0.87);
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    }

    .p-selectbutton .p-button .p-button-icon-left,
    .p-selectbutton .p-button .p-button-icon-right {
      color: rgba(255, 255, 255, 0.6);
    }

    .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
      background: rgba(255, 255, 255, 0.03);
      border-color: #424b57;
      color: rgba(255, 255, 255, 0.87);
    }

    .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
    .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
      color: rgba(255, 255, 255, 0.6);
    }

    .p-selectbutton .p-button.p-highlight {
      background: #60a5fa;
      border-color: #60a5fa;
      color: #030712;
    }

    .p-selectbutton .p-button.p-highlight .p-button-icon-left,
    .p-selectbutton .p-button.p-highlight .p-button-icon-right {
      color: #030712;
    }

    .p-selectbutton .p-button.p-highlight:hover {
      background: #93c5fd;
      border-color: #93c5fd;
      color: #030712;
    }

    .p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
    .p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
      color: #030712;
    }

    p-selectbutton.ng-dirty.ng-invalid>.p-selectbutton>.p-button {
      border-color: #fca5a5;
    }

    .p-slider {
      background: #424b57;
      border: 0 none;
      border-radius: 6px;
    }

    .p-slider.p-slider-horizontal {
      height: 0.286rem;
    }

    .p-slider.p-slider-horizontal .p-slider-handle {
      margin-top: -0.5715rem;
      margin-left: -0.5715rem;
    }

    .p-slider.p-slider-vertical {
      height: 100%;
      width: 0.286rem;
    }

    .p-slider.p-slider-vertical .p-slider-handle {
      height: 1.143rem;
      width: 1.143rem;
      margin-left: -0.5715rem;
      margin-bottom: -0.5715rem;
    }

    .p-slider .p-slider-handle {
      height: 1.143rem;
      width: 1.143rem;
      background: #424b57;
      border: 2px solid #60a5fa;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    }

    .p-slider .p-slider-handle:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-slider .p-slider-range {
      background: #60a5fa;
    }

    .p-slider:not(.p-disabled) .p-slider-handle:hover {
      background: #60a5fa;
      border-color: #60a5fa;
    }

    .p-slider.p-slider-animate.p-slider-horizontal .p-slider-handle {
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, left 0.2s;
    }

    .p-slider.p-slider-animate.p-slider-horizontal .p-slider-range {
      transition: width 0.2s;
    }

    .p-slider.p-slider-animate.p-slider-vertical .p-slider-handle {
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, bottom 0.2s;
    }

    .p-slider.p-slider-animate.p-slider-vertical .p-slider-range {
      transition: height 0.2s;
    }

    .p-togglebutton.p-button {
      background: #1f2937;
      border: 1px solid #424b57;
      color: rgba(255, 255, 255, 0.87);
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    }

    .p-togglebutton.p-button .p-button-icon-left,
    .p-togglebutton.p-button .p-button-icon-right {
      color: rgba(255, 255, 255, 0.6);
    }

    .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
      background: rgba(255, 255, 255, 0.03);
      border-color: #424b57;
      color: rgba(255, 255, 255, 0.87);
    }

    .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
    .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
      color: rgba(255, 255, 255, 0.6);
    }

    .p-togglebutton.p-button.p-highlight {
      background: #60a5fa;
      border-color: #60a5fa;
      color: #030712;
    }

    .p-togglebutton.p-button.p-highlight .p-button-icon-left,
    .p-togglebutton.p-button.p-highlight .p-button-icon-right {
      color: #030712;
    }

    .p-togglebutton.p-button.p-highlight:hover {
      background: #93c5fd;
      border-color: #93c5fd;
      color: #030712;
    }

    .p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
    .p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
      color: #030712;
    }

    p-togglebutton.ng-dirty.ng-invalid>.p-togglebutton.p-button {
      border-color: #fca5a5;
    }

    .p-treeselect {
      background: #111827;
      border: 1px solid #424b57;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
      border-radius: 6px;
    }

    .p-treeselect:not(.p-disabled):hover {
      border-color: #60a5fa;
    }

    .p-treeselect:not(.p-disabled).p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
      border-color: #60a5fa;
    }

    .p-treeselect .p-treeselect-label {
      padding: 0.75rem 0.75rem;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    }

    .p-treeselect .p-treeselect-label.p-placeholder {
      color: rgba(255, 255, 255, 0.6);
    }

    .p-treeselect.p-treeselect-chip .p-treeselect-token {
      padding: 0.375rem 0.75rem;
      margin-right: 0.5rem;
      background: #424b57;
      color: rgba(255, 255, 255, 0.87);
      border-radius: 16px;
    }

    .p-treeselect .p-treeselect-trigger {
      background: transparent;
      color: rgba(255, 255, 255, 0.6);
      width: 3rem;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    .p-treeselect.p-variant-filled {
      background-color: #424b57;
    }

    .p-treeselect.p-variant-filled:enabled:hover {
      background-color: #424b57;
    }

    .p-treeselect.p-variant-filled:enabled:focus {
      background-color: #424b57;
    }

    p-treeselect.ng-invalid.ng-dirty>.p-treeselect {
      border-color: #fca5a5;
    }

    .p-inputwrapper-filled .p-treeselect.p-treeselect-chip .p-treeselect-label {
      padding: 0.375rem 0.75rem;
    }

    .p-treeselect-panel {
      background: #1f2937;
      color: rgba(255, 255, 255, 0.87);
      border: 1px solid #424b57;
      border-radius: 6px;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    }

    .p-treeselect-panel .p-treeselect-header {
      padding: 0.75rem 1.25rem;
      border-bottom: 1px solid #424b57;
      color: rgba(255, 255, 255, 0.87);
      background: #1f2937;
      margin: 0;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
    }

    .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container {
      margin-right: 0.5rem;
    }

    .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter {
      padding-right: 1.75rem;
    }

    .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter-icon {
      right: 0.75rem;
      color: rgba(255, 255, 255, 0.6);
    }

    .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter {
      padding-right: 3.5rem;
    }

    .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter-clear-icon {
      right: 2.5rem;
    }

    .p-treeselect-panel .p-treeselect-header .p-treeselect-close {
      width: 2rem;
      height: 2rem;
      color: rgba(255, 255, 255, 0.6);
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

    .p-treeselect-panel .p-treeselect-header .p-treeselect-close:enabled:hover {
      color: rgba(255, 255, 255, 0.87);
      border-color: transparent;
      background: rgba(255, 255, 255, 0.03);
    }

    .p-treeselect-panel .p-treeselect-header .p-treeselect-close:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
      border: 0 none;
    }

    .p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
      padding: 0.75rem 1.25rem;
      color: rgba(255, 255, 255, 0.87);
      background: transparent;
    }

    .p-input-filled .p-treeselect {
      background: #424b57;
    }

    .p-input-filled .p-treeselect:not(.p-disabled):hover {
      background-color: #424b57;
    }

    .p-input-filled .p-treeselect:not(.p-disabled).p-focus {
      background-color: #424b57;
    }

    p-treeselect.p-treeselect-clearable .p-treeselect-label-container {
      padding-right: 1.75rem;
    }

    p-treeselect.p-treeselect-clearable .p-treeselect-clear-icon {
      color: rgba(255, 255, 255, 0.6);
      right: 3rem;
    }

    .p-button {
      color: #030712;
      background: #60a5fa;
      border: 1px solid #60a5fa;
      padding: 0.75rem 1.25rem;
      font-size: 1rem;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
      border-radius: 6px;
      outline-color: transparent;
    }

    .p-button:not(:disabled):hover {
      background: #93c5fd;
      color: #030712;
      border-color: #93c5fd;
    }

    .p-button:not(:disabled):active {
      background: #bfdbfe;
      color: #030712;
      border-color: #bfdbfe;
    }

    .p-button.p-button-outlined {
      background-color: transparent;
      color: #60a5fa;
      border: 1px solid;
    }

    .p-button.p-button-outlined:not(:disabled):hover {
      background: rgba(96, 165, 250, 0.04);
      color: #60a5fa;
      border: 1px solid;
    }

    .p-button.p-button-outlined:not(:disabled):active {
      background: rgba(96, 165, 250, 0.16);
      color: #60a5fa;
      border: 1px solid;
    }

    .p-button.p-button-outlined.p-button-plain {
      color: rgba(255, 255, 255, 0.6);
      border-color: rgba(255, 255, 255, 0.6);
    }

    .p-button.p-button-outlined.p-button-plain:not(:disabled):hover {
      background: rgba(255, 255, 255, 0.03);
      color: rgba(255, 255, 255, 0.6);
    }

    .p-button.p-button-outlined.p-button-plain:not(:disabled):active {
      background: rgba(255, 255, 255, 0.16);
      color: rgba(255, 255, 255, 0.6);
    }

    .p-button.p-button-text {
      background-color: transparent;
      color: #60a5fa;
      border-color: transparent;
    }

    .p-button.p-button-text:not(:disabled):hover {
      background: rgba(96, 165, 250, 0.04);
      color: #60a5fa;
      border-color: transparent;
    }

    .p-button.p-button-text:not(:disabled):active {
      background: rgba(96, 165, 250, 0.16);
      color: #60a5fa;
      border-color: transparent;
    }

    .p-button.p-button-text.p-button-plain {
      color: rgba(255, 255, 255, 0.6);
    }

    .p-button.p-button-text.p-button-plain:not(:disabled):hover {
      background: rgba(255, 255, 255, 0.03);
      color: rgba(255, 255, 255, 0.6);
    }

    .p-button.p-button-text.p-button-plain:not(:disabled):active {
      background: rgba(255, 255, 255, 0.16);
      color: rgba(255, 255, 255, 0.6);
    }

    .p-button:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-button .p-button-label {
      transition-duration: 0.2s;
    }

    .p-button .p-button-icon-left {
      margin-right: 0.5rem;
    }

    .p-button .p-button-icon-right {
      margin-left: 0.5rem;
    }

    .p-button .p-button-icon-bottom {
      margin-top: 0.5rem;
    }

    .p-button .p-button-icon-top {
      margin-bottom: 0.5rem;
    }

    .p-button .p-badge {
      margin-left: 0.5rem;
      min-width: 1rem;
      height: 1rem;
      line-height: 1rem;
      color: #60a5fa;
      background-color: #030712;
    }

    .p-button.p-button-raised {
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    }

    .p-button.p-button-rounded {
      border-radius: 2rem;
    }

    .p-button.p-button-icon-only {
      width: 3rem;
      padding: 0.75rem 0;
    }

    .p-button.p-button-icon-only .p-button-icon-left,
    .p-button.p-button-icon-only .p-button-icon-right {
      margin: 0;
    }

    .p-button.p-button-icon-only.p-button-rounded {
      border-radius: 50%;
      height: 3rem;
    }

    .p-button.p-button-sm {
      font-size: 0.875rem;
      padding: 0.65625rem 1.09375rem;
    }

    .p-button.p-button-sm .p-button-icon {
      font-size: 0.875rem;
    }

    .p-button.p-button-lg {
      font-size: 1.25rem;
      padding: 0.9375rem 1.5625rem;
    }

    .p-button.p-button-lg .p-button-icon {
      font-size: 1.25rem;
    }

    .p-button.p-button-loading-label-only .p-button-label {
      margin-left: 0.5rem;
    }

    .p-button.p-button-loading-label-only .p-button-loading-icon {
      margin-right: 0;
    }

    .p-fluid .p-button {
      width: 100%;
    }

    .p-fluid .p-button-icon-only {
      width: 3rem;
    }

    .p-fluid .p-button-group {
      display: flex;
    }

    .p-fluid .p-button-group .p-button {
      flex: 1;
    }

    .p-button.p-button-secondary,
    .p-button-group.p-button-secondary>.p-button,
    .p-splitbutton.p-button-secondary>.p-button {
      color: #020617;
      background: #94a3b8;
      border: 1px solid #94a3b8;
    }

    .p-button.p-button-secondary:not(:disabled):hover,
    .p-button-group.p-button-secondary>.p-button:not(:disabled):hover,
    .p-splitbutton.p-button-secondary>.p-button:not(:disabled):hover {
      background: #cbd5e1;
      color: #020617;
      border-color: #cbd5e1;
    }

    .p-button.p-button-secondary:not(:disabled):focus,
    .p-button-group.p-button-secondary>.p-button:not(:disabled):focus,
    .p-splitbutton.p-button-secondary>.p-button:not(:disabled):focus {
      box-shadow: 0 0 0 1px #b4bfcd;
    }

    .p-button.p-button-secondary:not(:disabled):active,
    .p-button-group.p-button-secondary>.p-button:not(:disabled):active,
    .p-splitbutton.p-button-secondary>.p-button:not(:disabled):active {
      background: #e2e8f0;
      color: #020617;
      border-color: #e2e8f0;
    }

    .p-button.p-button-secondary.p-button-outlined,
    .p-button-group.p-button-secondary>.p-button.p-button-outlined,
    .p-splitbutton.p-button-secondary>.p-button.p-button-outlined {
      background-color: transparent;
      color: #94a3b8;
      border: 1px solid;
    }

    .p-button.p-button-secondary.p-button-outlined:not(:disabled):hover,
    .p-button-group.p-button-secondary>.p-button.p-button-outlined:not(:disabled):hover,
    .p-splitbutton.p-button-secondary>.p-button.p-button-outlined:not(:disabled):hover {
      background: rgba(148, 163, 184, 0.04);
      color: #94a3b8;
      border: 1px solid;
    }

    .p-button.p-button-secondary.p-button-outlined:not(:disabled):active,
    .p-button-group.p-button-secondary>.p-button.p-button-outlined:not(:disabled):active,
    .p-splitbutton.p-button-secondary>.p-button.p-button-outlined:not(:disabled):active {
      background: rgba(148, 163, 184, 0.16);
      color: #94a3b8;
      border: 1px solid;
    }

    .p-button.p-button-secondary.p-button-text,
    .p-button-group.p-button-secondary>.p-button.p-button-text,
    .p-splitbutton.p-button-secondary>.p-button.p-button-text {
      background-color: transparent;
      color: #94a3b8;
      border-color: transparent;
    }

    .p-button.p-button-secondary.p-button-text:not(:disabled):hover,
    .p-button-group.p-button-secondary>.p-button.p-button-text:not(:disabled):hover,
    .p-splitbutton.p-button-secondary>.p-button.p-button-text:not(:disabled):hover {
      background: rgba(148, 163, 184, 0.04);
      border-color: transparent;
      color: #94a3b8;
    }

    .p-button.p-button-secondary.p-button-text:not(:disabled):active,
    .p-button-group.p-button-secondary>.p-button.p-button-text:not(:disabled):active,
    .p-splitbutton.p-button-secondary>.p-button.p-button-text:not(:disabled):active {
      background: rgba(148, 163, 184, 0.16);
      border-color: transparent;
      color: #94a3b8;
    }

    .p-button.p-button-info,
    .p-button-group.p-button-info>.p-button,
    .p-splitbutton.p-button-info>.p-button {
      color: #082f49;
      background: #38bdf8;
      border: 1px solid #38bdf8;
    }

    .p-button.p-button-info:not(:disabled):hover,
    .p-button-group.p-button-info>.p-button:not(:disabled):hover,
    .p-splitbutton.p-button-info>.p-button:not(:disabled):hover {
      background: #7dd3fc;
      color: #082f49;
      border-color: #7dd3fc;
    }

    .p-button.p-button-info:not(:disabled):focus,
    .p-button-group.p-button-info>.p-button:not(:disabled):focus,
    .p-splitbutton.p-button-info>.p-button:not(:disabled):focus {
      box-shadow: 0 0 0 1px #74d1fa;
    }

    .p-button.p-button-info:not(:disabled):active,
    .p-button-group.p-button-info>.p-button:not(:disabled):active,
    .p-splitbutton.p-button-info>.p-button:not(:disabled):active {
      background: #bae6fd;
      color: #082f49;
      border-color: #bae6fd;
    }

    .p-button.p-button-info.p-button-outlined,
    .p-button-group.p-button-info>.p-button.p-button-outlined,
    .p-splitbutton.p-button-info>.p-button.p-button-outlined {
      background-color: transparent;
      color: #38bdf8;
      border: 1px solid;
    }

    .p-button.p-button-info.p-button-outlined:not(:disabled):hover,
    .p-button-group.p-button-info>.p-button.p-button-outlined:not(:disabled):hover,
    .p-splitbutton.p-button-info>.p-button.p-button-outlined:not(:disabled):hover {
      background: rgba(56, 189, 248, 0.04);
      color: #38bdf8;
      border: 1px solid;
    }

    .p-button.p-button-info.p-button-outlined:not(:disabled):active,
    .p-button-group.p-button-info>.p-button.p-button-outlined:not(:disabled):active,
    .p-splitbutton.p-button-info>.p-button.p-button-outlined:not(:disabled):active {
      background: rgba(56, 189, 248, 0.16);
      color: #38bdf8;
      border: 1px solid;
    }

    .p-button.p-button-info.p-button-text,
    .p-button-group.p-button-info>.p-button.p-button-text,
    .p-splitbutton.p-button-info>.p-button.p-button-text {
      background-color: transparent;
      color: #38bdf8;
      border-color: transparent;
    }

    .p-button.p-button-info.p-button-text:not(:disabled):hover,
    .p-button-group.p-button-info>.p-button.p-button-text:not(:disabled):hover,
    .p-splitbutton.p-button-info>.p-button.p-button-text:not(:disabled):hover {
      background: rgba(56, 189, 248, 0.04);
      border-color: transparent;
      color: #38bdf8;
    }

    .p-button.p-button-info.p-button-text:not(:disabled):active,
    .p-button-group.p-button-info>.p-button.p-button-text:not(:disabled):active,
    .p-splitbutton.p-button-info>.p-button.p-button-text:not(:disabled):active {
      background: rgba(56, 189, 248, 0.16);
      border-color: transparent;
      color: #38bdf8;
    }

    .p-button.p-button-success,
    .p-button-group.p-button-success>.p-button,
    .p-splitbutton.p-button-success>.p-button {
      color: #052e16;
      background: #4ade80;
      border: 1px solid #4ade80;
    }

    .p-button.p-button-success:not(:disabled):hover,
    .p-button-group.p-button-success>.p-button:not(:disabled):hover,
    .p-splitbutton.p-button-success>.p-button:not(:disabled):hover {
      background: #86efac;
      color: #052e16;
      border-color: #86efac;
    }

    .p-button.p-button-success:not(:disabled):focus,
    .p-button-group.p-button-success>.p-button:not(:disabled):focus,
    .p-splitbutton.p-button-success>.p-button:not(:disabled):focus {
      box-shadow: 0 0 0 1px #80e8a6;
    }

    .p-button.p-button-success:not(:disabled):active,
    .p-button-group.p-button-success>.p-button:not(:disabled):active,
    .p-splitbutton.p-button-success>.p-button:not(:disabled):active {
      background: #bbf7d0;
      color: #052e16;
      border-color: #bbf7d0;
    }

    .p-button.p-button-success.p-button-outlined,
    .p-button-group.p-button-success>.p-button.p-button-outlined,
    .p-splitbutton.p-button-success>.p-button.p-button-outlined {
      background-color: transparent;
      color: #4ade80;
      border: 1px solid;
    }

    .p-button.p-button-success.p-button-outlined:not(:disabled):hover,
    .p-button-group.p-button-success>.p-button.p-button-outlined:not(:disabled):hover,
    .p-splitbutton.p-button-success>.p-button.p-button-outlined:not(:disabled):hover {
      background: rgba(74, 222, 128, 0.04);
      color: #4ade80;
      border: 1px solid;
    }

    .p-button.p-button-success.p-button-outlined:not(:disabled):active,
    .p-button-group.p-button-success>.p-button.p-button-outlined:not(:disabled):active,
    .p-splitbutton.p-button-success>.p-button.p-button-outlined:not(:disabled):active {
      background: rgba(74, 222, 128, 0.16);
      color: #4ade80;
      border: 1px solid;
    }

    .p-button.p-button-success.p-button-text,
    .p-button-group.p-button-success>.p-button.p-button-text,
    .p-splitbutton.p-button-success>.p-button.p-button-text {
      background-color: transparent;
      color: #4ade80;
      border-color: transparent;
    }

    .p-button.p-button-success.p-button-text:not(:disabled):hover,
    .p-button-group.p-button-success>.p-button.p-button-text:not(:disabled):hover,
    .p-splitbutton.p-button-success>.p-button.p-button-text:not(:disabled):hover {
      background: rgba(74, 222, 128, 0.04);
      border-color: transparent;
      color: #4ade80;
    }

    .p-button.p-button-success.p-button-text:not(:disabled):active,
    .p-button-group.p-button-success>.p-button.p-button-text:not(:disabled):active,
    .p-splitbutton.p-button-success>.p-button.p-button-text:not(:disabled):active {
      background: rgba(74, 222, 128, 0.16);
      border-color: transparent;
      color: #4ade80;
    }

    .p-button.p-button-warning,
    .p-button-group.p-button-warning>.p-button,
    .p-splitbutton.p-button-warning>.p-button {
      color: #431407;
      background: #fb923c;
      border: 1px solid #fb923c;
    }

    .p-button.p-button-warning:not(:disabled):hover,
    .p-button-group.p-button-warning>.p-button:not(:disabled):hover,
    .p-splitbutton.p-button-warning>.p-button:not(:disabled):hover {
      background: #fdba74;
      color: #431407;
      border-color: #fdba74;
    }

    .p-button.p-button-warning:not(:disabled):focus,
    .p-button-group.p-button-warning>.p-button:not(:disabled):focus,
    .p-splitbutton.p-button-warning>.p-button:not(:disabled):focus {
      box-shadow: 0 0 0 1px #fcb377;
    }

    .p-button.p-button-warning:not(:disabled):active,
    .p-button-group.p-button-warning>.p-button:not(:disabled):active,
    .p-splitbutton.p-button-warning>.p-button:not(:disabled):active {
      background: #fed7aa;
      color: #431407;
      border-color: #fed7aa;
    }

    .p-button.p-button-warning.p-button-outlined,
    .p-button-group.p-button-warning>.p-button.p-button-outlined,
    .p-splitbutton.p-button-warning>.p-button.p-button-outlined {
      background-color: transparent;
      color: #fb923c;
      border: 1px solid;
    }

    .p-button.p-button-warning.p-button-outlined:not(:disabled):hover,
    .p-button-group.p-button-warning>.p-button.p-button-outlined:not(:disabled):hover,
    .p-splitbutton.p-button-warning>.p-button.p-button-outlined:not(:disabled):hover {
      background: rgba(251, 146, 60, 0.04);
      color: #fb923c;
      border: 1px solid;
    }

    .p-button.p-button-warning.p-button-outlined:not(:disabled):active,
    .p-button-group.p-button-warning>.p-button.p-button-outlined:not(:disabled):active,
    .p-splitbutton.p-button-warning>.p-button.p-button-outlined:not(:disabled):active {
      background: rgba(251, 146, 60, 0.16);
      color: #fb923c;
      border: 1px solid;
    }

    .p-button.p-button-warning.p-button-text,
    .p-button-group.p-button-warning>.p-button.p-button-text,
    .p-splitbutton.p-button-warning>.p-button.p-button-text {
      background-color: transparent;
      color: #fb923c;
      border-color: transparent;
    }

    .p-button.p-button-warning.p-button-text:not(:disabled):hover,
    .p-button-group.p-button-warning>.p-button.p-button-text:not(:disabled):hover,
    .p-splitbutton.p-button-warning>.p-button.p-button-text:not(:disabled):hover {
      background: rgba(251, 146, 60, 0.04);
      border-color: transparent;
      color: #fb923c;
    }

    .p-button.p-button-warning.p-button-text:not(:disabled):active,
    .p-button-group.p-button-warning>.p-button.p-button-text:not(:disabled):active,
    .p-splitbutton.p-button-warning>.p-button.p-button-text:not(:disabled):active {
      background: rgba(251, 146, 60, 0.16);
      border-color: transparent;
      color: #fb923c;
    }

    .p-button.p-button-help,
    .p-button-group.p-button-help>.p-button,
    .p-splitbutton.p-button-help>.p-button {
      color: #3b0764;
      background: #c084fc;
      border: 1px solid #c084fc;
    }

    .p-button.p-button-help:not(:disabled):hover,
    .p-button-group.p-button-help>.p-button:not(:disabled):hover,
    .p-splitbutton.p-button-help>.p-button:not(:disabled):hover {
      background: #d8b4fe;
      color: #3b0764;
      border-color: #d8b4fe;
    }

    .p-button.p-button-help:not(:disabled):focus,
    .p-button-group.p-button-help>.p-button:not(:disabled):focus,
    .p-splitbutton.p-button-help>.p-button:not(:disabled):focus {
      box-shadow: 0 0 0 1px #d3a9fd;
    }

    .p-button.p-button-help:not(:disabled):active,
    .p-button-group.p-button-help>.p-button:not(:disabled):active,
    .p-splitbutton.p-button-help>.p-button:not(:disabled):active {
      background: #e9d5ff;
      color: #3b0764;
      border-color: #e9d5ff;
    }

    .p-button.p-button-help.p-button-outlined,
    .p-button-group.p-button-help>.p-button.p-button-outlined,
    .p-splitbutton.p-button-help>.p-button.p-button-outlined {
      background-color: transparent;
      color: #c084fc;
      border: 1px solid;
    }

    .p-button.p-button-help.p-button-outlined:not(:disabled):hover,
    .p-button-group.p-button-help>.p-button.p-button-outlined:not(:disabled):hover,
    .p-splitbutton.p-button-help>.p-button.p-button-outlined:not(:disabled):hover {
      background: rgba(192, 132, 252, 0.04);
      color: #c084fc;
      border: 1px solid;
    }

    .p-button.p-button-help.p-button-outlined:not(:disabled):active,
    .p-button-group.p-button-help>.p-button.p-button-outlined:not(:disabled):active,
    .p-splitbutton.p-button-help>.p-button.p-button-outlined:not(:disabled):active {
      background: rgba(192, 132, 252, 0.16);
      color: #c084fc;
      border: 1px solid;
    }

    .p-button.p-button-help.p-button-text,
    .p-button-group.p-button-help>.p-button.p-button-text,
    .p-splitbutton.p-button-help>.p-button.p-button-text {
      background-color: transparent;
      color: #c084fc;
      border-color: transparent;
    }

    .p-button.p-button-help.p-button-text:not(:disabled):hover,
    .p-button-group.p-button-help>.p-button.p-button-text:not(:disabled):hover,
    .p-splitbutton.p-button-help>.p-button.p-button-text:not(:disabled):hover {
      background: rgba(192, 132, 252, 0.04);
      border-color: transparent;
      color: #c084fc;
    }

    .p-button.p-button-help.p-button-text:not(:disabled):active,
    .p-button-group.p-button-help>.p-button.p-button-text:not(:disabled):active,
    .p-splitbutton.p-button-help>.p-button.p-button-text:not(:disabled):active {
      background: rgba(192, 132, 252, 0.16);
      border-color: transparent;
      color: #c084fc;
    }

    .p-button.p-button-danger,
    .p-button-group.p-button-danger>.p-button,
    .p-splitbutton.p-button-danger>.p-button {
      color: #450a0a;
      background: #f87171;
      border: 1px solid #f87171;
    }

    .p-button.p-button-danger:not(:disabled):hover,
    .p-button-group.p-button-danger>.p-button:not(:disabled):hover,
    .p-splitbutton.p-button-danger>.p-button:not(:disabled):hover {
      background: #fca5a5;
      color: #450a0a;
      border-color: #fca5a5;
    }

    .p-button.p-button-danger:not(:disabled):focus,
    .p-button-group.p-button-danger>.p-button:not(:disabled):focus,
    .p-splitbutton.p-button-danger>.p-button:not(:disabled):focus {
      box-shadow: 0 0 0 1px #fa9c9c;
    }

    .p-button.p-button-danger:not(:disabled):active,
    .p-button-group.p-button-danger>.p-button:not(:disabled):active,
    .p-splitbutton.p-button-danger>.p-button:not(:disabled):active {
      background: #fecaca;
      color: #450a0a;
      border-color: #fecaca;
    }

    .p-button.p-button-danger.p-button-outlined,
    .p-button-group.p-button-danger>.p-button.p-button-outlined,
    .p-splitbutton.p-button-danger>.p-button.p-button-outlined {
      background-color: transparent;
      color: #f87171;
      border: 1px solid;
    }

    .p-button.p-button-danger.p-button-outlined:not(:disabled):hover,
    .p-button-group.p-button-danger>.p-button.p-button-outlined:not(:disabled):hover,
    .p-splitbutton.p-button-danger>.p-button.p-button-outlined:not(:disabled):hover {
      background: rgba(248, 113, 113, 0.04);
      color: #f87171;
      border: 1px solid;
    }

    .p-button.p-button-danger.p-button-outlined:not(:disabled):active,
    .p-button-group.p-button-danger>.p-button.p-button-outlined:not(:disabled):active,
    .p-splitbutton.p-button-danger>.p-button.p-button-outlined:not(:disabled):active {
      background: rgba(248, 113, 113, 0.16);
      color: #f87171;
      border: 1px solid;
    }

    .p-button.p-button-danger.p-button-text,
    .p-button-group.p-button-danger>.p-button.p-button-text,
    .p-splitbutton.p-button-danger>.p-button.p-button-text {
      background-color: transparent;
      color: #f87171;
      border-color: transparent;
    }

    .p-button.p-button-danger.p-button-text:not(:disabled):hover,
    .p-button-group.p-button-danger>.p-button.p-button-text:not(:disabled):hover,
    .p-splitbutton.p-button-danger>.p-button.p-button-text:not(:disabled):hover {
      background: rgba(248, 113, 113, 0.04);
      border-color: transparent;
      color: #f87171;
    }

    .p-button.p-button-danger.p-button-text:not(:disabled):active,
    .p-button-group.p-button-danger>.p-button.p-button-text:not(:disabled):active,
    .p-splitbutton.p-button-danger>.p-button.p-button-text:not(:disabled):active {
      background: rgba(248, 113, 113, 0.16);
      border-color: transparent;
      color: #f87171;
    }

    .p-button.p-button-contrast,
    .p-button-group.p-button-contrast>.p-button,
    .p-splitbutton.p-button-contrast>.p-button {
      color: #111827;
      background: #ffffff;
      border: 1px solid #ffffff;
    }

    .p-button.p-button-contrast:not(:disabled):hover,
    .p-button-group.p-button-contrast>.p-button:not(:disabled):hover,
    .p-splitbutton.p-button-contrast>.p-button:not(:disabled):hover {
      background: #f3f4f6;
      color: #111827;
      border-color: #f3f4f6;
    }

    .p-button.p-button-contrast:not(:disabled):focus,
    .p-button-group.p-button-contrast>.p-button:not(:disabled):focus,
    .p-splitbutton.p-button-contrast>.p-button:not(:disabled):focus {
      box-shadow: none;
    }

    .p-button.p-button-contrast:not(:disabled):active,
    .p-button-group.p-button-contrast>.p-button:not(:disabled):active,
    .p-splitbutton.p-button-contrast>.p-button:not(:disabled):active {
      background: #e5e7eb;
      color: #111827;
      border-color: #e5e7eb;
    }

    .p-button.p-button-contrast.p-button-outlined,
    .p-button-group.p-button-contrast>.p-button.p-button-outlined,
    .p-splitbutton.p-button-contrast>.p-button.p-button-outlined {
      background-color: transparent;
      color: #ffffff;
      border: 1px solid;
    }

    .p-button.p-button-contrast.p-button-outlined:not(:disabled):hover,
    .p-button-group.p-button-contrast>.p-button.p-button-outlined:not(:disabled):hover,
    .p-splitbutton.p-button-contrast>.p-button.p-button-outlined:not(:disabled):hover {
      background: rgba(255, 255, 255, 0.04);
      color: #ffffff;
      border: 1px solid;
    }

    .p-button.p-button-contrast.p-button-outlined:not(:disabled):active,
    .p-button-group.p-button-contrast>.p-button.p-button-outlined:not(:disabled):active,
    .p-splitbutton.p-button-contrast>.p-button.p-button-outlined:not(:disabled):active {
      background: rgba(255, 255, 255, 0.16);
      color: #ffffff;
      border: 1px solid;
    }

    .p-button.p-button-contrast.p-button-text,
    .p-button-group.p-button-contrast>.p-button.p-button-text,
    .p-splitbutton.p-button-contrast>.p-button.p-button-text {
      background-color: transparent;
      color: #ffffff;
      border-color: transparent;
    }

    .p-button.p-button-contrast.p-button-text:not(:disabled):hover,
    .p-button-group.p-button-contrast>.p-button.p-button-text:not(:disabled):hover,
    .p-splitbutton.p-button-contrast>.p-button.p-button-text:not(:disabled):hover {
      background: rgba(255, 255, 255, 0.04);
      border-color: transparent;
      color: #ffffff;
    }

    .p-button.p-button-contrast.p-button-text:not(:disabled):active,
    .p-button-group.p-button-contrast>.p-button.p-button-text:not(:disabled):active,
    .p-splitbutton.p-button-contrast>.p-button.p-button-text:not(:disabled):active {
      background: rgba(255, 255, 255, 0.16);
      border-color: transparent;
      color: #ffffff;
    }

    .p-button.p-button-link {
      color: #60a5fa;
      background: transparent;
      border: transparent;
    }

    .p-button.p-button-link:not(:disabled):hover {
      background: transparent;
      color: #60a5fa;
      border-color: transparent;
    }

    .p-button.p-button-link:not(:disabled):hover .p-button-label {
      text-decoration: underline;
    }

    .p-button.p-button-link:not(:disabled):focus {
      background: transparent;
      box-shadow: 0 0 0 1px rgba(96, 165, 250, 0.2);
      border-color: transparent;
    }

    .p-button.p-button-link:not(:disabled):active {
      background: transparent;
      color: #60a5fa;
      border-color: transparent;
    }

    .p-speeddial-button.p-button.p-button-icon-only {
      width: 4rem;
      height: 4rem;
    }

    .p-speeddial-button.p-button.p-button-icon-only .p-button-icon {
      font-size: 1.3rem;
    }

    .p-speeddial-button.p-button.p-button-icon-only .p-icon {
      width: 1.3rem;
      height: 1.3rem;
    }

    .p-speeddial-list {
      outline: 0 none;
    }

    .p-speeddial-item.p-focus>.p-speeddial-action {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-speeddial-action {
      width: 3rem;
      height: 3rem;
      background: rgba(255, 255, 255, 0.87);
      color: #111827;
    }

    .p-speeddial-action:hover {
      background: rgba(255, 255, 255, 0.6);
      color: #111827;
    }

    .p-speeddial-direction-up .p-speeddial-item {
      margin: 0.25rem 0;
    }

    .p-speeddial-direction-up .p-speeddial-item:first-child {
      margin-bottom: 0.5rem;
    }

    .p-speeddial-direction-down .p-speeddial-item {
      margin: 0.25rem 0;
    }

    .p-speeddial-direction-down .p-speeddial-item:first-child {
      margin-top: 0.5rem;
    }

    .p-speeddial-direction-left .p-speeddial-item {
      margin: 0 0.25rem;
    }

    .p-speeddial-direction-left .p-speeddial-item:first-child {
      margin-right: 0.5rem;
    }

    .p-speeddial-direction-right .p-speeddial-item {
      margin: 0 0.25rem;
    }

    .p-speeddial-direction-right .p-speeddial-item:first-child {
      margin-left: 0.5rem;
    }

    .p-speeddial-circle .p-speeddial-item,
    .p-speeddial-semi-circle .p-speeddial-item,
    .p-speeddial-quarter-circle .p-speeddial-item {
      margin: 0;
    }

    .p-speeddial-circle .p-speeddial-item:first-child,
    .p-speeddial-circle .p-speeddial-item:last-child,
    .p-speeddial-semi-circle .p-speeddial-item:first-child,
    .p-speeddial-semi-circle .p-speeddial-item:last-child,
    .p-speeddial-quarter-circle .p-speeddial-item:first-child,
    .p-speeddial-quarter-circle .p-speeddial-item:last-child {
      margin: 0;
    }

    .p-speeddial-mask {
      background-color: rgba(0, 0, 0, 0.4);
    }

    .p-splitbutton {
      border-radius: 6px;
    }

    .p-splitbutton.p-button-rounded {
      border-radius: 2rem;
    }

    .p-splitbutton.p-button-rounded>.p-button {
      border-radius: 2rem;
    }

    .p-splitbutton.p-button-raised {
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    }

    .p-carousel .p-carousel-content .p-carousel-prev,
    .p-carousel .p-carousel-content .p-carousel-next {
      width: 2rem;
      height: 2rem;
      color: rgba(255, 255, 255, 0.6);
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      margin: 0.5rem;
    }

    .p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
    .p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
      color: rgba(255, 255, 255, 0.87);
      border-color: transparent;
      background: rgba(255, 255, 255, 0.03);
    }

    .p-carousel .p-carousel-content .p-carousel-prev:focus-visible,
    .p-carousel .p-carousel-content .p-carousel-next:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-carousel .p-carousel-indicators {
      padding: 1rem;
    }

    .p-carousel .p-carousel-indicators .p-carousel-indicator {
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
    }

    .p-carousel .p-carousel-indicators .p-carousel-indicator button {
      background-color: #424b57;
      width: 2rem;
      height: 0.5rem;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      border-radius: 0;
    }

    .p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
      background: rgba(255, 255, 255, 0.03);
    }

    .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
      background: rgba(96, 165, 250, 0.16);
      color: rgba(255, 255, 255, 0.87);
    }

    .p-datatable .p-paginator-top {
      border-width: 1px 0 1px 0;
      border-radius: 0;
    }

    .p-datatable .p-paginator-bottom {
      border-width: 0 0 1px 0;
      border-radius: 0;
    }

    .p-datatable .p-datatable-header {
      background: #1f2937;
      color: rgba(255, 255, 255, 0.6);
      border: 1px solid #424b57;
      border-width: 0 0 1px 0;
      padding: 1rem 1rem;
      font-weight: 700;
    }

    .p-datatable .p-datatable-footer {
      background: #1f2937;
      color: rgba(255, 255, 255, 0.87);
      border: 1px solid #424b57;
      border-width: 0 0 1px 0;
      padding: 1rem 1rem;
      font-weight: 700;
    }

    .p-datatable .p-datatable-thead>tr>th {
      text-align: left;
      padding: 1rem 1rem;
      border: 1px solid #424b57;
      border-width: 0 0 1px 0;
      font-weight: 700;
      color: rgba(255, 255, 255, 0.87);
      background: #1f2937;
      transition: box-shadow 0.2s;
    }

    .p-datatable .p-datatable-tfoot>tr>td {
      text-align: left;
      padding: 1rem 1rem;
      border: 1px solid #424b57;
      border-width: 0 0 1px 0;
      font-weight: 700;
      color: rgba(255, 255, 255, 0.87);
      background: #1f2937;
    }

    .p-datatable .p-sortable-column .p-sortable-column-icon {
      color: rgba(255, 255, 255, 0.6);
      margin-left: 0.5rem;
    }

    .p-datatable .p-sortable-column .p-sortable-column-badge {
      border-radius: 50%;
      height: 1.143rem;
      min-width: 1.143rem;
      line-height: 1.143rem;
      color: rgba(255, 255, 255, 0.87);
      background: rgba(96, 165, 250, 0.16);
      margin-left: 0.5rem;
    }

    .p-datatable .p-sortable-column:not(.p-highlight):hover {
      background: rgba(255, 255, 255, 0.03);
      color: rgba(255, 255, 255, 0.87);
    }

    .p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-datatable .p-sortable-column.p-highlight {
      background: rgba(96, 165, 250, 0.16);
      color: rgba(255, 255, 255, 0.87);
    }

    .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-datatable .p-sortable-column.p-highlight:hover {
      background: rgba(96, 165, 250, 0.16);
      color: rgba(255, 255, 255, 0.87);
    }

    .p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-datatable .p-sortable-column:focus-visible {
      box-shadow: inset 0 0 0 0.15rem rgba(96, 165, 250, 0.2);
      outline: 0 none;
    }

    .p-datatable .p-datatable-tbody>tr {
      background: #1f2937;
      color: rgba(255, 255, 255, 0.87);
      transition: box-shadow 0.2s;
    }

    .p-datatable .p-datatable-tbody>tr>td {
      text-align: left;
      border: 1px solid #424b57;
      border-width: 0 0 1px 0;
      padding: 1rem 1rem;
    }

    .p-datatable .p-datatable-tbody>tr>td .p-row-toggler,
    .p-datatable .p-datatable-tbody>tr>td .p-row-editor-init,
    .p-datatable .p-datatable-tbody>tr>td .p-row-editor-save,
    .p-datatable .p-datatable-tbody>tr>td .p-row-editor-cancel {
      width: 2rem;
      height: 2rem;
      color: rgba(255, 255, 255, 0.6);
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

    .p-datatable .p-datatable-tbody>tr>td .p-row-toggler:enabled:hover,
    .p-datatable .p-datatable-tbody>tr>td .p-row-editor-init:enabled:hover,
    .p-datatable .p-datatable-tbody>tr>td .p-row-editor-save:enabled:hover,
    .p-datatable .p-datatable-tbody>tr>td .p-row-editor-cancel:enabled:hover {
      color: rgba(255, 255, 255, 0.87);
      border-color: transparent;
      background: rgba(255, 255, 255, 0.03);
    }

    .p-datatable .p-datatable-tbody>tr>td .p-row-toggler:focus-visible,
    .p-datatable .p-datatable-tbody>tr>td .p-row-editor-init:focus-visible,
    .p-datatable .p-datatable-tbody>tr>td .p-row-editor-save:focus-visible,
    .p-datatable .p-datatable-tbody>tr>td .p-row-editor-cancel:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-datatable .p-datatable-tbody>tr>td .p-row-editor-save {
      margin-right: 0.5rem;
    }

    .p-datatable .p-datatable-tbody>tr:focus-visible {
      outline: 0.15rem solid rgba(96, 165, 250, 0.2);
      outline-offset: -0.15rem;
    }

    .p-datatable .p-datatable-tbody>tr.p-highlight {
      background: rgba(96, 165, 250, 0.16);
      color: rgba(255, 255, 255, 0.87);
    }

    .p-datatable .p-datatable-tbody>tr.p-datatable-dragpoint-top>td {
      box-shadow: inset 0 2px 0 0 rgba(96, 165, 250, 0.16);
    }

    .p-datatable .p-datatable-tbody>tr.p-datatable-dragpoint-bottom>td {
      box-shadow: inset 0 -2px 0 0 rgba(96, 165, 250, 0.16);
    }

    .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody>tr:not(.p-highlight):hover {
      background: rgba(255, 255, 255, 0.03);
      color: rgba(255, 255, 255, 0.87);
    }

    .p-datatable .p-column-resizer-helper {
      background: #60a5fa;
    }

    .p-datatable .p-datatable-scrollable-header,
    .p-datatable .p-datatable-scrollable-footer {
      background: #1f2937;
    }

    .p-datatable.p-datatable-scrollable>.p-datatable-wrapper>.p-datatable-table>.p-datatable-thead,
    .p-datatable.p-datatable-scrollable>.p-datatable-wrapper>.p-datatable-table>.p-datatable-tfoot,
    .p-datatable.p-datatable-scrollable>.p-datatable-wrapper>.p-scroller-viewport>.p-scroller>.p-datatable-table>.p-datatable-thead,
    .p-datatable.p-datatable-scrollable>.p-datatable-wrapper>.p-scroller-viewport>.p-scroller>.p-datatable-table>.p-datatable-tfoot {
      background-color: #1f2937;
    }

    .p-datatable .p-datatable-loading-icon {
      font-size: 2rem;
    }

    .p-datatable.p-datatable-gridlines .p-datatable-header {
      border-width: 1px 1px 0 1px;
    }

    .p-datatable.p-datatable-gridlines .p-datatable-footer {
      border-width: 0 1px 1px 1px;
    }

    .p-datatable.p-datatable-gridlines .p-paginator-top {
      border-width: 0 1px 0 1px;
    }

    .p-datatable.p-datatable-gridlines .p-paginator-bottom {
      border-width: 0 1px 1px 1px;
    }

    .p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th {
      border-width: 1px 0 1px 1px;
    }

    .p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th:last-child {
      border-width: 1px;
    }

    .p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td {
      border-width: 1px 0 0 1px;
    }

    .p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td:last-child {
      border-width: 1px 1px 0 1px;
    }

    .p-datatable.p-datatable-gridlines .p-datatable-tbody>tr:last-child>td {
      border-width: 1px 0 1px 1px;
    }

    .p-datatable.p-datatable-gridlines .p-datatable-tbody>tr:last-child>td:last-child {
      border-width: 1px;
    }

    .p-datatable.p-datatable-gridlines .p-datatable-tfoot>tr>td {
      border-width: 1px 0 1px 1px;
    }

    .p-datatable.p-datatable-gridlines .p-datatable-tfoot>tr>td:last-child {
      border-width: 1px 1px 1px 1px;
    }

    .p-datatable.p-datatable-gridlines .p-datatable-thead+.p-datatable-tfoot>tr>td {
      border-width: 0 0 1px 1px;
    }

    .p-datatable.p-datatable-gridlines .p-datatable-thead+.p-datatable-tfoot>tr>td:last-child {
      border-width: 0 1px 1px 1px;
    }

    .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody>tr>td {
      border-width: 0 0 1px 1px;
    }

    .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody>tr>td:last-child {
      border-width: 0 1px 1px 1px;
    }

    .p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody>tr:last-child>td {
      border-width: 0 0 0 1px;
    }

    .p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody>tr:last-child>td:last-child {
      border-width: 0 1px 0 1px;
    }

    .p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(even) {
      background: #1c2532;
    }

    .p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(even).p-highlight {
      background: rgba(96, 165, 250, 0.16);
      color: rgba(255, 255, 255, 0.87);
    }

    .p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(even).p-highlight .p-row-toggler {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(even).p-highlight .p-row-toggler:hover {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-datatable.p-datatable-sm .p-datatable-header {
      padding: 0.5rem 0.5rem;
    }

    .p-datatable.p-datatable-sm .p-datatable-thead>tr>th {
      padding: 0.5rem 0.5rem;
    }

    .p-datatable.p-datatable-sm .p-datatable-tbody>tr>td {
      padding: 0.5rem 0.5rem;
    }

    .p-datatable.p-datatable-sm .p-datatable-tfoot>tr>td {
      padding: 0.5rem 0.5rem;
    }

    .p-datatable.p-datatable-sm .p-datatable-footer {
      padding: 0.5rem 0.5rem;
    }

    .p-datatable.p-datatable-lg .p-datatable-header {
      padding: 1.25rem 1.25rem;
    }

    .p-datatable.p-datatable-lg .p-datatable-thead>tr>th {
      padding: 1.25rem 1.25rem;
    }

    .p-datatable.p-datatable-lg .p-datatable-tbody>tr>td {
      padding: 1.25rem 1.25rem;
    }

    .p-datatable.p-datatable-lg .p-datatable-tfoot>tr>td {
      padding: 1.25rem 1.25rem;
    }

    .p-datatable.p-datatable-lg .p-datatable-footer {
      padding: 1.25rem 1.25rem;
    }

    .p-dataview .p-paginator-top {
      border-width: 1px 0 1px 0;
      border-radius: 0;
    }

    .p-dataview .p-paginator-bottom {
      border-width: 0 0 1px 0;
      border-radius: 0;
    }

    .p-dataview .p-dataview-header {
      background: #1f2937;
      color: rgba(255, 255, 255, 0.6);
      border: 1px solid #424b57;
      border-width: 0 0 1px 0;
      padding: 1rem 1rem;
      font-weight: 700;
    }

    .p-dataview .p-dataview-content {
      background: #1f2937;
      color: rgba(255, 255, 255, 0.87);
      border: 0 none;
      padding: 0;
    }

    .p-dataview .p-dataview-footer {
      background: #1f2937;
      color: rgba(255, 255, 255, 0.87);
      border: 1px solid #424b57;
      border-width: 0 0 1px 0;
      padding: 1rem 1rem;
      font-weight: 700;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    .p-dataview .p-dataview-loading-icon {
      font-size: 2rem;
    }

    .p-dataview .p-dataview-emptymessage {
      padding: 1.25rem;
    }

    .p-column-filter-row .p-column-filter-menu-button,
    .p-column-filter-row .p-column-filter-clear-button {
      margin-left: 0.5rem;
    }

    .p-column-filter-menu-button {
      width: 2rem;
      height: 2rem;
      color: rgba(255, 255, 255, 0.6);
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

    .p-column-filter-menu-button:hover {
      color: rgba(255, 255, 255, 0.87);
      border-color: transparent;
      background: rgba(255, 255, 255, 0.03);
    }

    .p-column-filter-menu-button.p-column-filter-menu-button-open,
    .p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
      background: rgba(255, 255, 255, 0.03);
      color: rgba(255, 255, 255, 0.87);
    }

    .p-column-filter-menu-button.p-column-filter-menu-button-active,
    .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
      background: rgba(96, 165, 250, 0.16);
      color: rgba(255, 255, 255, 0.87);
    }

    .p-column-filter-menu-button:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-column-filter-clear-button {
      width: 2rem;
      height: 2rem;
      color: rgba(255, 255, 255, 0.6);
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

    .p-column-filter-clear-button:hover {
      color: rgba(255, 255, 255, 0.87);
      border-color: transparent;
      background: rgba(255, 255, 255, 0.03);
    }

    .p-column-filter-clear-button:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-column-filter-overlay {
      background: #1f2937;
      color: rgba(255, 255, 255, 0.87);
      border: 1px solid #424b57;
      border-radius: 6px;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
      min-width: 12.5rem;
    }

    .p-column-filter-overlay .p-column-filter-row-items {
      padding: 0.75rem 0;
    }

    .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
      margin: 0;
      padding: 0.75rem 1.25rem;
      border: 0 none;
      color: rgba(255, 255, 255, 0.87);
      background: transparent;
      transition: box-shadow 0.2s;
      border-radius: 0;
    }

    .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:first-child {
      margin-top: 0;
    }

    .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(96, 165, 250, 0.16);
    }

    .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(255, 255, 255, 0.03);
    }

    .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.15rem rgba(96, 165, 250, 0.2);
    }

    .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
      border-top: 1px solid #424b57;
      margin: 0.25rem 0;
    }

    .p-column-filter-overlay-menu .p-column-filter-operator {
      padding: 0.75rem 1.25rem;
      border-bottom: 1px solid #424b57;
      color: rgba(255, 255, 255, 0.87);
      background: #1f2937;
      margin: 0;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
    }

    .p-column-filter-overlay-menu .p-column-filter-constraint {
      padding: 1.25rem;
      border-bottom: 1px solid #424b57;
    }

    .p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
      margin-bottom: 0.5rem;
    }

    .p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
      margin-top: 0.5rem;
    }

    .p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
      border-bottom: 0 none;
    }

    .p-column-filter-overlay-menu .p-column-filter-add-rule {
      padding: 0.75rem 1.25rem;
    }

    .p-column-filter-overlay-menu .p-column-filter-buttonbar {
      padding: 1.25rem;
    }

    .p-orderlist .p-orderlist-controls {
      padding: 1.25rem;
    }

    .p-orderlist .p-orderlist-controls .p-button {
      margin-bottom: 0.5rem;
    }

    .p-orderlist .p-orderlist-list-container {
      background: #1f2937;
      border: 1px solid #424b57;
      border-radius: 6px;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
      outline-color: transparent;
    }

    .p-orderlist .p-orderlist-list-container.p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
      border-color: #60a5fa;
    }

    .p-orderlist .p-orderlist-header {
      color: rgba(255, 255, 255, 0.87);
      padding: 1.25rem;
      font-weight: 700;
    }

    .p-orderlist .p-orderlist-header .p-orderlist-title {
      font-weight: 700;
    }

    .p-orderlist .p-orderlist-filter-container {
      padding: 1.25rem;
      background: #1f2937;
      border: 1px solid #424b57;
      border-bottom: 0 none;
    }

    .p-orderlist .p-orderlist-filter-container .p-orderlist-filter-input {
      padding-right: 1.75rem;
    }

    .p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
      right: 0.75rem;
      color: rgba(255, 255, 255, 0.6);
    }

    .p-orderlist .p-orderlist-list {
      color: rgba(255, 255, 255, 0.87);
      padding: 0.75rem 0;
      outline: 0 none;
    }

    .p-orderlist .p-orderlist-list:not(:first-child) {
      border-top: 1px solid #424b57;
    }

    .p-orderlist .p-orderlist-list .p-orderlist-item {
      padding: 0.75rem 1.25rem;
      margin: 0;
      border: 0 none;
      color: rgba(255, 255, 255, 0.87);
      background: transparent;
      transition: box-shadow 0.2s;
    }

    .p-orderlist .p-orderlist-list .p-orderlist-item:first-child {
      margin-top: 0;
    }

    .p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
      background: rgba(255, 255, 255, 0.03);
      color: rgba(255, 255, 255, 0.87);
    }

    .p-orderlist .p-orderlist-list .p-orderlist-item.p-focus {
      color: rgba(255, 255, 255, 0.87);
      background: #424b57;
    }

    .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(96, 165, 250, 0.16);
    }

    .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight.p-focus {
      background: rgba(96, 165, 250, 0.24);
    }

    .p-orderlist .p-orderlist-list .p-orderlist-empty-message {
      padding: 0.75rem 1.25rem;
      color: rgba(255, 255, 255, 0.87);
    }

    .p-orderlist .p-orderlist-list:not(.cdk-drop-list-dragging) .p-orderlist-item:not(.p-highlight):hover {
      background: rgba(255, 255, 255, 0.03);
      color: rgba(255, 255, 255, 0.87);
    }

    .p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(even) {
      background: #1c2532;
    }

    .p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(even):hover {
      background: rgba(255, 255, 255, 0.03);
    }

    .p-orderlist-item.cdk-drag-preview {
      padding: 0.75rem 1.25rem;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
      border: 0 none;
      color: rgba(255, 255, 255, 0.87);
      background: #1f2937;
      margin: 0;
    }

    .p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
      background: rgba(255, 255, 255, 0.03);
      color: rgba(255, 255, 255, 0.87);
    }

    .p-organizationchart .p-organizationchart-node-content.p-highlight {
      background: rgba(96, 165, 250, 0.16);
      color: rgba(255, 255, 255, 0.87);
    }

    .p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
      color: rgba(7, 99, 212, 0.16);
    }

    .p-organizationchart .p-organizationchart-line-down {
      background: #424b57;
    }

    .p-organizationchart .p-organizationchart-line-left {
      border-right: 1px solid #424b57;
      border-color: #424b57;
    }

    .p-organizationchart .p-organizationchart-line-top {
      border-top: 1px solid #424b57;
      border-color: #424b57;
    }

    .p-organizationchart .p-organizationchart-node-content {
      border: 1px solid #424b57;
      background: #1f2937;
      color: rgba(255, 255, 255, 0.87);
      padding: 1.25rem;
    }

    .p-organizationchart .p-organizationchart-node-content .p-node-toggler {
      background: inherit;
      color: inherit;
      border-radius: 50%;
    }

    .p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-paginator {
      background: #1f2937;
      color: rgba(255, 255, 255, 0.6);
      border: solid #424b57;
      border-width: 1px;
      padding: 0.5rem 1rem;
      border-radius: 6px;
    }

    .p-paginator .p-paginator-first,
    .p-paginator .p-paginator-prev,
    .p-paginator .p-paginator-next,
    .p-paginator .p-paginator-last {
      background-color: transparent;
      border: 0 none;
      color: rgba(255, 255, 255, 0.6);
      min-width: 3rem;
      height: 3rem;
      margin: 0.143rem;
      transition: box-shadow 0.2s;
      border-radius: 6px;
    }

    .p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
      background: rgba(255, 255, 255, 0.03);
      border-color: transparent;
      color: rgba(255, 255, 255, 0.87);
    }

    .p-paginator .p-paginator-first {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    .p-paginator .p-paginator-last {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    .p-paginator .p-dropdown {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      height: 3rem;
    }

    .p-paginator .p-dropdown .p-dropdown-label {
      padding-right: 0;
    }

    .p-paginator .p-paginator-page-input {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }

    .p-paginator .p-paginator-page-input .p-inputtext {
      max-width: 3rem;
    }

    .p-paginator .p-paginator-current {
      background-color: transparent;
      border: 0 none;
      color: rgba(255, 255, 255, 0.6);
      min-width: 3rem;
      height: 3rem;
      margin: 0.143rem;
      padding: 0 0.5rem;
    }

    .p-paginator .p-paginator-pages .p-paginator-page {
      background-color: transparent;
      border: 0 none;
      color: rgba(255, 255, 255, 0.6);
      min-width: 3rem;
      height: 3rem;
      margin: 0.143rem;
      transition: box-shadow 0.2s;
      border-radius: 6px;
    }

    .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
      background: rgba(96, 165, 250, 0.16);
      border-color: rgba(96, 165, 250, 0.16);
      color: rgba(255, 255, 255, 0.87);
    }

    .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
      background: rgba(255, 255, 255, 0.03);
      border-color: transparent;
      color: rgba(255, 255, 255, 0.87);
    }

    .p-picklist .p-picklist-buttons {
      padding: 1.25rem;
    }

    .p-picklist .p-picklist-buttons .p-button {
      margin-bottom: 0.5rem;
    }

    .p-picklist .p-picklist-list-wrapper {
      background: #1f2937;
      border: 1px solid #424b57;
      border-radius: 6px;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
      outline-color: transparent;
    }

    .p-picklist .p-picklist-list-wrapper.p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
      border-color: #60a5fa;
    }

    .p-picklist .p-picklist-header {
      color: rgba(255, 255, 255, 0.87);
      padding: 1.25rem;
      font-weight: 700;
    }

    .p-picklist .p-picklist-header .p-picklist-title {
      font-weight: 700;
    }

    .p-picklist .p-picklist-filter-container {
      padding: 1.25rem;
      background: #1f2937;
      border: 1px solid #424b57;
      border-bottom: 0 none;
    }

    .p-picklist .p-picklist-filter-container .p-picklist-filter-input {
      padding-right: 1.75rem;
    }

    .p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
      right: 0.75rem;
      color: rgba(255, 255, 255, 0.6);
    }

    .p-picklist .p-picklist-list {
      color: rgba(255, 255, 255, 0.87);
      padding: 0.75rem 0;
      outline: 0 none;
    }

    .p-picklist .p-picklist-list:not(:first-child) {
      border-top: 1px solid #424b57;
    }

    .p-picklist .p-picklist-list .p-picklist-item {
      padding: 0.75rem 1.25rem;
      margin: 0;
      border: 0 none;
      color: rgba(255, 255, 255, 0.87);
      background: transparent;
      transition: box-shadow 0.2s;
    }

    .p-picklist .p-picklist-list .p-picklist-item:first-child {
      margin-top: 0;
    }

    .p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
      background: rgba(255, 255, 255, 0.03);
      color: rgba(255, 255, 255, 0.87);
    }

    .p-picklist .p-picklist-list .p-picklist-item.p-focus {
      color: rgba(255, 255, 255, 0.87);
      background: #424b57;
    }

    .p-picklist .p-picklist-list .p-picklist-item.p-highlight {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(96, 165, 250, 0.16);
    }

    .p-picklist .p-picklist-list .p-picklist-item.p-highlight.p-focus {
      background: rgba(96, 165, 250, 0.24);
    }

    .p-picklist .p-picklist-list .p-picklist-empty-message {
      padding: 0.75rem 1.25rem;
      color: rgba(255, 255, 255, 0.87);
    }

    .p-picklist .p-picklist-list:not(.cdk-drop-list-dragging) .p-picklist-item:not(.p-highlight):hover {
      background: rgba(255, 255, 255, 0.03);
      color: rgba(255, 255, 255, 0.87);
    }

    .p-picklist.p-picklist-striped .p-picklist-list .p-picklist-item:nth-child(even) {
      background: #1c2532;
    }

    .p-picklist.p-picklist-striped .p-picklist-list .p-picklist-item:nth-child(even):hover {
      background: rgba(255, 255, 255, 0.03);
    }

    .p-picklist-item.cdk-drag-preview {
      padding: 0.75rem 1.25rem;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
      border: 0 none;
      color: rgba(255, 255, 255, 0.87);
      background: #1f2937;
      margin: 0;
    }

    .p-timeline .p-timeline-event-marker {
      border: 2px solid #60a5fa;
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
      background-color: #030712;
    }

    .p-timeline .p-timeline-event-connector {
      background-color: #424b57;
    }

    .p-timeline.p-timeline-vertical .p-timeline-event-opposite,
    .p-timeline.p-timeline-vertical .p-timeline-event-content {
      padding: 0 1rem;
    }

    .p-timeline.p-timeline-vertical .p-timeline-event-connector {
      width: 2px;
    }

    .p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
    .p-timeline.p-timeline-horizontal .p-timeline-event-content {
      padding: 1rem 0;
    }

    .p-timeline.p-timeline-horizontal .p-timeline-event-connector {
      height: 2px;
    }

    .p-tree {
      border: 1px solid #424b57;
      background: #1f2937;
      color: rgba(255, 255, 255, 0.87);
      padding: 1.25rem;
      border-radius: 6px;
    }

    .p-tree .p-tree-container .p-treenode {
      padding: 0.143rem;
      outline: 0 none;
    }

    .p-tree .p-tree-container .p-treenode:focus>.p-treenode-content {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.15rem rgba(96, 165, 250, 0.2);
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content {
      border-radius: 6px;
      transition: box-shadow 0.2s;
      padding: 0.5rem;
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
      margin-right: 0.5rem;
      width: 2rem;
      height: 2rem;
      color: rgba(255, 255, 255, 0.6);
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
      color: rgba(255, 255, 255, 0.87);
      border-color: transparent;
      background: rgba(255, 255, 255, 0.03);
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
      margin-right: 0.5rem;
      color: rgba(255, 255, 255, 0.6);
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
      margin-right: 0.5rem;
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox.p-variant-filled .p-checkbox-box {
      background-color: #424b57;
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox.p-variant-filled .p-checkbox-box.p-highlight {
      background: #60a5fa;
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox.p-variant-filled:not(.p-disabled) .p-checkbox-box:hover {
      background-color: #424b57;
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox.p-variant-filled:not(.p-disabled) .p-checkbox-box.p-highlight:hover {
      background: #bfdbfe;
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
      background: rgba(96, 165, 250, 0.16);
      color: rgba(255, 255, 255, 0.87);
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
    .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
    .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
      background: rgba(255, 255, 255, 0.03);
      color: rgba(255, 255, 255, 0.87);
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
      background: rgba(255, 255, 255, 0.03);
      color: rgba(255, 255, 255, 0.87);
    }

    .p-tree .p-tree-filter-container {
      margin-bottom: 0.5rem;
    }

    .p-tree .p-tree-filter-container .p-tree-filter {
      width: 100%;
      padding-right: 1.75rem;
    }

    .p-tree .p-tree-filter-container .p-tree-filter-icon {
      right: 0.75rem;
      color: rgba(255, 255, 255, 0.6);
    }

    .p-tree .p-treenode-children {
      padding: 0 0 0 1rem;
    }

    .p-tree .p-tree-loading-icon {
      font-size: 2rem;
    }

    .p-tree .p-tree-loading-icon.p-icon {
      width: 2rem;
      height: 2rem;
    }

    .p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
      background-color: rgba(29, 127, 248, 0.16);
    }

    .p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
      border-radius: 6px;
      border: 1px solid #424b57;
      background-color: #1f2937;
      color: rgba(255, 255, 255, 0.87);
      padding: 0.5rem;
      transition: box-shadow 0.2s;
    }

    .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
      background-color: rgba(96, 165, 250, 0.16);
      color: rgba(255, 255, 255, 0.87);
    }

    .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-tree-toggler {
      margin-right: 0.5rem;
    }

    .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-icon {
      color: rgba(255, 255, 255, 0.6);
      margin-right: 0.5rem;
    }

    .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox {
      margin-right: 0.5rem;
    }

    .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-label:not(.p-highlight):hover {
      background-color: inherit;
      color: inherit;
    }

    .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
      background: rgba(255, 255, 255, 0.03);
      color: rgba(255, 255, 255, 0.87);
    }

    .p-tree.p-tree-horizontal .p-treenode .p-treenode-content:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-treetable .p-paginator-top {
      border-width: 1px 0 1px 0;
      border-radius: 0;
    }

    .p-treetable .p-paginator-bottom {
      border-width: 0 0 1px 0;
      border-radius: 0;
    }

    .p-treetable .p-treetable-header {
      background: #1f2937;
      color: rgba(255, 255, 255, 0.6);
      border: 1px solid #424b57;
      border-width: 0 0 1px 0;
      padding: 1rem 1rem;
      font-weight: 700;
    }

    .p-treetable .p-treetable-footer {
      background: #1f2937;
      color: rgba(255, 255, 255, 0.87);
      border: 1px solid #424b57;
      border-width: 0 0 1px 0;
      padding: 1rem 1rem;
      font-weight: 700;
    }

    .p-treetable .p-treetable-thead>tr>th {
      text-align: left;
      padding: 1rem 1rem;
      border: 1px solid #424b57;
      border-width: 0 0 1px 0;
      font-weight: 700;
      color: rgba(255, 255, 255, 0.87);
      background: #1f2937;
      transition: box-shadow 0.2s;
    }

    .p-treetable .p-treetable-tfoot>tr>td {
      text-align: left;
      padding: 1rem 1rem;
      border: 1px solid #424b57;
      border-width: 0 0 1px 0;
      font-weight: 700;
      color: rgba(255, 255, 255, 0.87);
      background: #1f2937;
    }

    .p-treetable .p-sortable-column {
      outline-color: rgba(96, 165, 250, 0.2);
    }

    .p-treetable .p-sortable-column .p-sortable-column-icon {
      color: rgba(255, 255, 255, 0.6);
      margin-left: 0.5rem;
    }

    .p-treetable .p-sortable-column .p-sortable-column-badge {
      border-radius: 50%;
      height: 1.143rem;
      min-width: 1.143rem;
      line-height: 1.143rem;
      color: rgba(255, 255, 255, 0.87);
      background: rgba(96, 165, 250, 0.16);
      margin-left: 0.5rem;
    }

    .p-treetable .p-sortable-column:not(.p-highlight):hover {
      background: rgba(255, 255, 255, 0.03);
      color: rgba(255, 255, 255, 0.87);
    }

    .p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-treetable .p-sortable-column.p-highlight {
      background: rgba(96, 165, 250, 0.16);
      color: rgba(255, 255, 255, 0.87);
    }

    .p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-treetable .p-treetable-tbody>tr {
      background: #1f2937;
      color: rgba(255, 255, 255, 0.87);
      transition: box-shadow 0.2s;
    }

    .p-treetable .p-treetable-tbody>tr>td {
      text-align: left;
      border: 1px solid #424b57;
      border-width: 0 0 1px 0;
      padding: 1rem 1rem;
    }

    .p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler {
      width: 2rem;
      height: 2rem;
      color: rgba(255, 255, 255, 0.6);
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      margin-right: 0.5rem;
    }

    .p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler:enabled:hover {
      color: rgba(255, 255, 255, 0.87);
      border-color: transparent;
      background: rgba(255, 255, 255, 0.03);
    }

    .p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler.p-icon {
      width: 2rem;
      height: 2rem;
    }

    .p-treetable .p-treetable-tbody>tr>td p-treetablecheckbox .p-checkbox {
      margin-right: 0.5rem;
    }

    .p-treetable .p-treetable-tbody>tr>td p-treetablecheckbox .p-checkbox .p-indeterminate .p-checkbox-icon {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-treetable .p-treetable-tbody>tr>td p-treetablecheckbox .p-checkbox.p-variant-filled .p-checkbox-box {
      background-color: #424b57;
    }

    .p-treetable .p-treetable-tbody>tr>td p-treetablecheckbox .p-checkbox.p-variant-filled .p-checkbox-box.p-highlight {
      background: #60a5fa;
    }

    .p-treetable .p-treetable-tbody>tr>td p-treetablecheckbox .p-checkbox.p-variant-filled:not(.p-disabled) .p-checkbox-box:hover {
      background-color: #424b57;
    }

    .p-treetable .p-treetable-tbody>tr>td p-treetablecheckbox .p-checkbox.p-variant-filled:not(.p-disabled) .p-checkbox-box.p-highlight:hover {
      background: #bfdbfe;
    }

    .p-treetable .p-treetable-tbody>tr:focus-visible {
      outline: 0.15rem solid rgba(96, 165, 250, 0.2);
      outline-offset: -0.15rem;
    }

    .p-treetable .p-treetable-tbody>tr.p-highlight {
      background: rgba(96, 165, 250, 0.16);
      color: rgba(255, 255, 255, 0.87);
    }

    .p-treetable .p-treetable-tbody>tr.p-highlight .p-treetable-toggler {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-treetable .p-treetable-tbody>tr.p-highlight .p-treetable-toggler:hover {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody>tr:not(.p-highlight):hover {
      background: rgba(255, 255, 255, 0.03);
      color: rgba(255, 255, 255, 0.87);
    }

    .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody>tr:not(.p-highlight):hover .p-treetable-toggler {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-treetable .p-column-resizer-helper {
      background: #60a5fa;
    }

    .p-treetable .p-treetable-scrollable-header,
    .p-treetable .p-treetable-scrollable-footer {
      background: #1f2937;
    }

    .p-treetable .p-treetable-loading-icon {
      font-size: 2rem;
    }

    .p-treetable .p-treetable-loading-icon.p-icon {
      width: 2rem;
      height: 2rem;
    }

    .p-treetable.p-treetable-gridlines .p-datatable-header {
      border-width: 1px 1px 0 1px;
    }

    .p-treetable.p-treetable-gridlines .p-treetable-footer {
      border-width: 0 1px 1px 1px;
    }

    .p-treetable.p-treetable-gridlines .p-treetable-top {
      border-width: 0 1px 0 1px;
    }

    .p-treetable.p-treetable-gridlines .p-treetable-bottom {
      border-width: 0 1px 1px 1px;
    }

    .p-treetable.p-treetable-gridlines .p-treetable-thead>tr>th {
      border-width: 1px;
    }

    .p-treetable.p-treetable-gridlines .p-treetable-tbody>tr>td {
      border-width: 1px;
    }

    .p-treetable.p-treetable-gridlines .p-treetable-tfoot>tr>td {
      border-width: 1px;
    }

    .p-treetable.p-treetable-sm .p-treetable-header {
      padding: 0.875rem 0.875rem;
    }

    .p-treetable.p-treetable-sm .p-treetable-thead>tr>th {
      padding: 0.5rem 0.5rem;
    }

    .p-treetable.p-treetable-sm .p-treetable-tbody>tr>td {
      padding: 0.5rem 0.5rem;
    }

    .p-treetable.p-treetable-sm .p-treetable-tfoot>tr>td {
      padding: 0.5rem 0.5rem;
    }

    .p-treetable.p-treetable-sm .p-treetable-footer {
      padding: 0.5rem 0.5rem;
    }

    .p-treetable.p-treetable-lg .p-treetable-header {
      padding: 1.25rem 1.25rem;
    }

    .p-treetable.p-treetable-lg .p-treetable-thead>tr>th {
      padding: 1.25rem 1.25rem;
    }

    .p-treetable.p-treetable-lg .p-treetable-tbody>tr>td {
      padding: 1.25rem 1.25rem;
    }

    .p-treetable.p-treetable-lg .p-treetable-tfoot>tr>td {
      padding: 1.25rem 1.25rem;
    }

    .p-treetable.p-treetable-lg .p-treetable-footer {
      padding: 1.25rem 1.25rem;
    }

    .p-virtualscroller .p-virtualscroller-header {
      background: #1f2937;
      color: rgba(255, 255, 255, 0.6);
      border: 1px solid #424b57;
      border-width: 0 0 1px 0;
      padding: 1rem 1rem;
      font-weight: 700;
    }

    .p-virtualscroller .p-virtualscroller-content {
      background: #1f2937;
      color: rgba(255, 255, 255, 0.87);
      border: 0 none;
      padding: 0;
    }

    .p-virtualscroller .p-virtualscroller-footer {
      background: #1f2937;
      color: rgba(255, 255, 255, 0.87);
      border: 1px solid #424b57;
      border-width: 0 0 1px 0;
      padding: 1rem 1rem;
      font-weight: 700;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    .p-accordion .p-accordion-header .p-accordion-header-link {
      padding: 1.25rem;
      border: 1px solid #424b57;
      color: rgba(255, 255, 255, 0.87);
      background: #1f2937;
      font-weight: 700;
      border-radius: 6px;
      transition: box-shadow 0.2s;
    }

    .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
      margin-right: 0.5rem;
    }

    .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
      background: rgba(255, 255, 255, 0.03);
      border-color: #424b57;
      color: rgba(255, 255, 255, 0.87);
    }

    .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
      background: #1f2937;
      border-color: #424b57;
      color: rgba(255, 255, 255, 0.87);
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
      border-color: #424b57;
      background: rgba(255, 255, 255, 0.03);
      color: rgba(255, 255, 255, 0.87);
    }

    .p-accordion .p-accordion-content {
      padding: 1.25rem;
      border: 1px solid #424b57;
      background: #1f2937;
      color: rgba(255, 255, 255, 0.87);
      border-top: 0;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    .p-accordion p-accordiontab .p-accordion-tab {
      margin-bottom: 4px;
    }

    .p-card {
      background: #1f2937;
      color: rgba(255, 255, 255, 0.87);
      box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
      border-radius: 6px;
    }

    .p-card .p-card-body {
      padding: 1.25rem;
    }

    .p-card .p-card-title {
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 0.5rem;
    }

    .p-card .p-card-subtitle {
      font-weight: 400;
      margin-bottom: 0.5rem;
      color: rgba(255, 255, 255, 0.6);
    }

    .p-card .p-card-content {
      padding: 1.25rem 0;
    }

    .p-card .p-card-footer {
      padding: 1.25rem 0 0 0;
    }

    .p-divider .p-divider-content {
      background-color: #1f2937;
    }

    .p-divider.p-divider-horizontal {
      margin: 1.25rem 0;
      padding: 0 1.25rem;
    }

    .p-divider.p-divider-horizontal:before {
      border-top: 1px #424b57;
    }

    .p-divider.p-divider-horizontal .p-divider-content {
      padding: 0 0.5rem;
    }

    .p-divider.p-divider-vertical {
      margin: 0 1.25rem;
      padding: 1.25rem 0;
    }

    .p-divider.p-divider-vertical:before {
      border-left: 1px #424b57;
    }

    .p-divider.p-divider-vertical .p-divider-content {
      padding: 0.5rem 0;
    }

    .p-fieldset {
      border: 1px solid #424b57;
      background: #1f2937;
      color: rgba(255, 255, 255, 0.87);
      border-radius: 6px;
    }

    .p-fieldset .p-fieldset-legend {
      padding: 1.25rem;
      border: 1px solid #424b57;
      color: rgba(255, 255, 255, 0.87);
      background: #1f2937;
      font-weight: 700;
      border-radius: 6px;
    }

    .p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
      padding: 0;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

    .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
      padding: 1.25rem;
      color: rgba(255, 255, 255, 0.87);
      border-radius: 6px;
      transition: box-shadow 0.2s;
    }

    .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
      margin-right: 0.5rem;
    }

    .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
      background: rgba(255, 255, 255, 0.03);
      border-color: #424b57;
      color: rgba(255, 255, 255, 0.87);
    }

    .p-fieldset .p-fieldset-content {
      padding: 1.25rem;
    }

    .p-panel .p-panel-header {
      border: 1px solid #424b57;
      padding: 1.25rem;
      background: #1f2937;
      color: rgba(255, 255, 255, 0.87);
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
    }

    .p-panel .p-panel-header .p-panel-title {
      font-weight: 700;
    }

    .p-panel .p-panel-header .p-panel-header-icon {
      width: 2rem;
      height: 2rem;
      color: rgba(255, 255, 255, 0.6);
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

    .p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
      color: rgba(255, 255, 255, 0.87);
      border-color: transparent;
      background: rgba(255, 255, 255, 0.03);
    }

    .p-panel .p-panel-header .p-panel-header-icon:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-panel.p-panel-toggleable .p-panel-header {
      padding: 0.75rem 1.25rem;
    }

    .p-panel .p-panel-content {
      padding: 1.25rem;
      border: 1px solid #424b57;
      background: #1f2937;
      color: rgba(255, 255, 255, 0.87);
      border-top: 0 none;
    }

    .p-panel .p-panel-content:last-child {
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    .p-panel .p-panel-footer {
      padding: 0.75rem 1.25rem;
      border: 1px solid #424b57;
      background: #1f2937;
      color: rgba(255, 255, 255, 0.87);
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
      border-top: 0 none;
    }

    .p-panel .p-panel-icons-end {
      order: 2;
      margin-left: auto;
    }

    .p-panel .p-panel-icons-start {
      order: 0;
      margin-right: 0.5rem;
    }

    .p-panel .p-panel-icons-center {
      order: 2;
      width: 100%;
      text-align: center;
    }

    .p-scrollpanel .p-scrollpanel-bar {
      background: #424b57;
      border: 0 none;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    }

    .p-scrollpanel .p-scrollpanel-bar:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-splitter {
      border: 1px solid #424b57;
      background: #1f2937;
      border-radius: 6px;
      color: rgba(255, 255, 255, 0.87);
    }

    .p-splitter .p-splitter-gutter {
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      background: rgba(255, 255, 255, 0.03);
    }

    .p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
      background: #424b57;
    }

    .p-splitter .p-splitter-gutter .p-splitter-gutter-handle:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-splitter .p-splitter-gutter-resizing {
      background: #424b57;
    }

    .p-tabview .p-tabview-nav-content {
      scroll-padding-inline: 3rem;
    }

    .p-tabview .p-tabview-nav {
      background: transparent;
      border: 1px solid #424b57;
      border-width: 0 0 2px 0;
    }

    .p-tabview .p-tabview-nav li {
      margin-right: 0;
    }

    .p-tabview .p-tabview-nav li .p-tabview-nav-link {
      border: solid #424b57;
      border-width: 0 0 2px 0;
      border-color: transparent transparent #424b57 transparent;
      background: #1f2937;
      color: rgba(255, 255, 255, 0.6);
      padding: 1.25rem;
      font-weight: 700;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      transition: box-shadow 0.2s;
      margin: 0 0 -2px 0;
    }

    .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
      background: #1f2937;
      border-color: #60a5fa;
      color: rgba(255, 255, 255, 0.87);
    }

    .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
      background: #1f2937;
      border-color: #60a5fa;
      color: #60a5fa;
    }

    .p-tabview .p-tabview-left-icon {
      margin-right: 0.5rem;
    }

    .p-tabview .p-tabview-right-icon {
      margin-left: 0.5rem;
    }

    .p-tabview .p-tabview-close {
      margin-left: 0.5rem;
    }

    .p-tabview .p-tabview-nav-btn.p-link {
      background: #1f2937;
      color: #60a5fa;
      width: 3rem;
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
      border-radius: 0;
    }

    .p-tabview .p-tabview-nav-btn.p-link:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-tabview .p-tabview-panels {
      background: #1f2937;
      padding: 1.25rem;
      border: 0 none;
      color: rgba(255, 255, 255, 0.87);
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    .p-toolbar {
      background: #1f2937;
      border: 1px solid #424b57;
      padding: 1.25rem;
      border-radius: 6px;
      gap: 0.5rem;
    }

    .p-toolbar .p-toolbar-separator {
      margin: 0 0.5rem;
    }

    .p-stepper .p-stepper-nav {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style-type: none;
      overflow-x: auto;
    }

    .p-stepper-vertical .p-stepper-nav {
      flex-direction: column;
    }

    .p-stepper-header {
      position: relative;
      display: flex;
      flex: 1 1 auto;
      align-items: center;
    }

    .p-stepper-header:last-of-type {
      flex: initial;
    }

    .p-stepper-header .p-stepper-action {
      border: 0 none;
      display: inline-flex;
      align-items: center;
      text-decoration: none;
      cursor: pointer;
    }

    .p-stepper-header .p-stepper-action:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-stepper.p-stepper-readonly .p-stepper-header {
      cursor: auto;
    }

    .p-stepper-header.p-highlight .p-stepper-action {
      cursor: default;
    }

    .p-stepper-title {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    .p-stepper-number {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .p-stepper-separator {
      flex: 1 1 0;
    }

    .p-stepper .p-stepper-nav {
      display: flex;
      justify-content: space-between;
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .p-stepper .p-stepper-header {
      padding: 0.5rem;
    }

    .p-stepper .p-stepper-header .p-stepper-action {
      transition: box-shadow 0.2s;
      border-radius: 6px;
      background: transparent;
      outline-color: transparent;
    }

    .p-stepper .p-stepper-header .p-stepper-action .p-stepper-number {
      color: rgba(255, 255, 255, 0.87);
      border: 0 none;
      border-width: 2px;
      background: transparent;
      min-width: 2rem;
      height: 2rem;
      line-height: 2rem;
      font-size: 1.143rem;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

    .p-stepper .p-stepper-header .p-stepper-action .p-stepper-title {
      margin-left: 0.5rem;
      color: rgba(255, 255, 255, 0.6);
      font-weight: 700;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

    .p-stepper .p-stepper-header .p-stepper-action:not(.p-disabled):focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-stepper .p-stepper-header.p-highlight .p-stepper-number {
      background: rgba(96, 165, 250, 0.16);
      color: rgba(255, 255, 255, 0.87);
    }

    .p-stepper .p-stepper-header.p-highlight .p-stepper-title {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-stepper .p-stepper-header:not(.p-disabled):focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-stepper .p-stepper-header:has(~ .p-highlight) .p-stepper-separator {
      background-color: #60a5fa;
    }

    .p-stepper .p-stepper-panels {
      background: #1f2937;
      padding: 1.25rem;
      color: rgba(255, 255, 255, 0.87);
    }

    .p-stepper .p-stepper-separator {
      background-color: #424b57;
      width: 100%;
      height: 2px;
      margin-inline-start: 1rem;
      transition: box-shadow 0.2s;
    }

    .p-stepper.p-stepper-vertical {
      display: flex;
      flex-direction: column;
    }

    .p-stepper.p-stepper-vertical .p-stepper-toggleable-content {
      display: flex;
      flex: 1 1 auto;
      background: #1f2937;
      color: rgba(255, 255, 255, 0.87);
    }

    .p-stepper.p-stepper-vertical .p-stepper-panel {
      display: flex;
      flex-direction: column;
      flex: initial;
    }

    .p-stepper.p-stepper-vertical .p-stepper-panel.p-stepper-panel-active {
      flex: 1 1 auto;
    }

    .p-stepper.p-stepper-vertical .p-stepper-panel .p-stepper-header {
      flex: initial;
    }

    .p-stepper.p-stepper-vertical .p-stepper-panel .p-stepper-content {
      width: 100%;
      padding-left: 1rem;
    }

    .p-stepper.p-stepper-vertical .p-stepper-panel .p-stepper-separator {
      flex: 0 0 auto;
      width: 2px;
      height: auto;
      margin-inline-start: calc(1.75rem + 2px);
    }

    .p-stepper.p-stepper-vertical .p-stepper-panel:has(~ .p-stepper-panel-active) .p-stepper-separator {
      background-color: #60a5fa;
    }

    .p-stepper.p-stepper-vertical .p-stepper-panel:last-of-type .p-stepper-content {
      padding-left: 3rem;
    }

    .p-confirm-popup {
      background: #1f2937;
      color: rgba(255, 255, 255, 0.87);
      border: 1px solid #424b57;
      border-radius: 6px;
      box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    }

    .p-confirm-popup .p-confirm-popup-content {
      padding: 1.25rem;
    }

    .p-confirm-popup .p-confirm-popup-footer {
      text-align: right;
      padding: 0.75rem 1.25rem;
    }

    .p-confirm-popup .p-confirm-popup-footer button {
      margin: 0 0.5rem 0 0;
      width: auto;
    }

    .p-confirm-popup .p-confirm-popup-footer button:last-child {
      margin: 0;
    }

    .p-confirm-popup:after {
      border: solid transparent;
      border-color: rgba(31, 41, 55, 0);
      border-bottom-color: #1f2937;
    }

    .p-confirm-popup:before {
      border: solid transparent;
      border-color: rgba(66, 75, 87, 0);
      border-bottom-color: #424b57;
    }

    .p-confirm-popup.p-confirm-popup-flipped:after {
      border-top-color: #1f2937;
    }

    .p-confirm-popup.p-confirm-popup-flipped:before {
      border-top-color: #424b57;
    }

    .p-confirm-popup .p-confirm-popup-icon {
      font-size: 1.5rem;
    }

    .p-confirm-popup .p-confirm-popup-icon.p-icon {
      width: 1.5rem;
      height: 1.5rem;
    }

    .p-confirm-popup .p-confirm-popup-message {
      margin-left: 1rem;
    }

    .p-dialog {
      border-radius: 6px;
      box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
      border: 1px solid #424b57;
    }

    .p-dialog .p-dialog-header {
      border-bottom: 0 none;
      background: #1f2937;
      color: rgba(255, 255, 255, 0.87);
      padding: 1.5rem;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
    }

    .p-dialog .p-dialog-header .p-dialog-title {
      font-weight: 700;
      font-size: 1.25rem;
    }

    .p-dialog .p-dialog-header .p-dialog-header-icon {
      width: 2rem;
      height: 2rem;
      color: rgba(255, 255, 255, 0.6);
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      margin-right: 0.5rem;
    }

    .p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
      color: rgba(255, 255, 255, 0.87);
      border-color: transparent;
      background: rgba(255, 255, 255, 0.03);
    }

    .p-dialog .p-dialog-header .p-dialog-header-icon:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
      margin-right: 0;
    }

    .p-dialog .p-dialog-content {
      background: #1f2937;
      color: rgba(255, 255, 255, 0.87);
      padding: 0 1.5rem 2rem 1.5rem;
    }

    .p-dialog .p-dialog-content:last-of-type {
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    .p-dialog .p-dialog-footer {
      border-top: 0 none;
      background: #1f2937;
      color: rgba(255, 255, 255, 0.87);
      padding: 0 1.5rem 1.5rem 1.5rem;
      text-align: right;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    .p-dialog .p-dialog-footer button {
      margin: 0 0.5rem 0 0;
      width: auto;
    }

    .p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
      font-size: 2rem;
    }

    .p-dialog.p-confirm-dialog .p-confirm-dialog-icon.p-icon {
      width: 2rem;
      height: 2rem;
    }

    .p-dialog.p-confirm-dialog .p-confirm-dialog-message {
      margin-left: 1rem;
    }

    .p-overlaypanel {
      background: #1f2937;
      color: rgba(255, 255, 255, 0.87);
      border: 1px solid #424b57;
      border-radius: 6px;
      box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    }

    .p-overlaypanel .p-overlaypanel-content {
      padding: 1.25rem;
    }

    .p-overlaypanel .p-overlaypanel-close {
      background: #60a5fa;
      color: #030712;
      width: 2rem;
      height: 2rem;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      border-radius: 50%;
      position: absolute;
      top: -1rem;
      right: -1rem;
    }

    .p-overlaypanel .p-overlaypanel-close:enabled:hover {
      background: #93c5fd;
      color: #030712;
    }

    .p-overlaypanel:after {
      border: solid transparent;
      border-color: rgba(31, 41, 55, 0);
      border-bottom-color: #1f2937;
    }

    .p-overlaypanel:before {
      border: solid transparent;
      border-color: rgba(66, 75, 87, 0);
      border-bottom-color: #3f4753;
    }

    .p-overlaypanel.p-overlaypanel-flipped:after {
      border-top-color: #1f2937;
    }

    .p-overlaypanel.p-overlaypanel-flipped:before {
      border-top-color: #424b57;
    }

    .p-sidebar {
      background: #1f2937;
      color: rgba(255, 255, 255, 0.87);
      border: 1px solid #424b57;
      box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    }

    .p-sidebar .p-sidebar-header {
      padding: 1.25rem;
    }

    .p-sidebar .p-sidebar-header .p-sidebar-close,
    .p-sidebar .p-sidebar-header .p-sidebar-icon {
      width: 2rem;
      height: 2rem;
      color: rgba(255, 255, 255, 0.6);
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

    .p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,
    .p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
      color: rgba(255, 255, 255, 0.87);
      border-color: transparent;
      background: rgba(255, 255, 255, 0.03);
    }

    .p-sidebar .p-sidebar-header .p-sidebar-close:focus-visible,
    .p-sidebar .p-sidebar-header .p-sidebar-icon:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-sidebar .p-sidebar-header+.p-sidebar-content {
      padding-top: 0;
    }

    .p-sidebar .p-sidebar-content {
      padding: 1.25rem;
    }

    .p-sidebar .p-sidebar-footer {
      padding: 1.25rem;
    }

    .p-tooltip .p-tooltip-text {
      background: #424b57;
      color: rgba(255, 255, 255, 0.87);
      padding: 0.75rem 0.75rem;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
      border-radius: 6px;
    }

    .p-tooltip.p-tooltip-right .p-tooltip-arrow {
      border-right-color: #424b57;
    }

    .p-tooltip.p-tooltip-left .p-tooltip-arrow {
      border-left-color: #424b57;
    }

    .p-tooltip.p-tooltip-top .p-tooltip-arrow {
      border-top-color: #424b57;
    }

    .p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
      border-bottom-color: #424b57;
    }

    .p-fileupload .p-fileupload-buttonbar {
      background: #1f2937;
      padding: 1.25rem;
      border: 1px solid #424b57;
      color: rgba(255, 255, 255, 0.87);
      border-bottom: 0 none;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
    }

    .p-fileupload .p-fileupload-buttonbar .p-button {
      margin-right: 0.5rem;
    }

    .p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-fileupload .p-fileupload-content {
      background: #1f2937;
      padding: 2rem 1rem;
      border: 1px solid #424b57;
      color: rgba(255, 255, 255, 0.87);
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    .p-fileupload .p-fileupload-content.p-fileupload-highlight {
      border-color: 1px dashed #60a5fa;
      border-style: dashed;
      background-color: rgba(96, 165, 250, 0.16);
    }

    .p-fileupload .p-progressbar {
      height: 0.25rem;
    }

    .p-fileupload .p-fileupload-row>div {
      padding: 1rem 1rem;
    }

    .p-fileupload.p-fileupload-advanced .p-message {
      margin-top: 0;
    }

    .p-fileupload-choose:not(.p-disabled):hover {
      background: #93c5fd;
      color: #030712;
      border-color: #93c5fd;
    }

    .p-fileupload-choose:not(.p-disabled):active {
      background: #bfdbfe;
      color: #030712;
      border-color: #bfdbfe;
    }

    .p-breadcrumb {
      background: #374151;
      border: 1px solid #424b57;
      border-radius: 6px;
      padding: 1rem;
    }

    .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link {
      transition: box-shadow 0.2s;
      border-radius: 6px;
    }

    .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-icon {
      color: rgba(255, 255, 255, 0.6);
    }

    .p-breadcrumb .p-breadcrumb-list li.p-menuitem-separator {
      margin: 0 0.5rem 0 0.5rem;
      color: rgba(255, 255, 255, 0.87);
    }

    .p-breadcrumb .p-breadcrumb-list li:last-child .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-breadcrumb .p-breadcrumb-list li:last-child .p-menuitem-icon {
      color: rgba(255, 255, 255, 0.6);
    }

    .p-contextmenu {
      padding: 0.25rem 0;
      background: #374151;
      color: rgba(255, 255, 255, 0.87);
      border: 1px solid #424b57;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
      border-radius: 6px;
      width: 12.5rem;
    }

    .p-contextmenu .p-contextmenu-root-list {
      outline: 0 none;
    }

    .p-contextmenu .p-submenu-list {
      padding: 0.25rem 0;
      background: #374151;
      border: 1px solid #424b57;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
      border-radius: 6px;
    }

    .p-contextmenu .p-menuitem>.p-menuitem-content {
      color: rgba(255, 255, 255, 0.87);
      transition: box-shadow 0.2s;
      border-radius: 0;
    }

    .p-contextmenu .p-menuitem>.p-menuitem-content .p-menuitem-link {
      color: rgba(255, 255, 255, 0.87);
      padding: 0.75rem 1.25rem;
      user-select: none;
    }

    .p-contextmenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-contextmenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-icon {
      color: rgba(255, 255, 255, 0.6);
      margin-right: 0.5rem;
    }

    .p-contextmenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.6);
    }

    .p-contextmenu .p-menuitem.p-highlight>.p-menuitem-content {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(96, 165, 250, 0.16);
    }

    .p-contextmenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-contextmenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-contextmenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-contextmenu .p-menuitem.p-highlight.p-focus>.p-menuitem-content {
      background: rgba(96, 165, 250, 0.24);
    }

    .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content {
      color: rgba(255, 255, 255, 0.87);
      background: #424b57;
    }

    .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(255, 255, 255, 0.03);
    }

    .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
    .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-contextmenu .p-menuitem-separator {
      border-top: 1px solid #424b57;
      margin: 0.25rem 0;
    }

    .p-contextmenu .p-submenu-icon {
      font-size: 0.875rem;
    }

    .p-contextmenu .p-submenu-icon.p-icon {
      width: 0.875rem;
      height: 0.875rem;
    }

    .p-dock .p-dock-list-container {
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.2);
      padding: 0.5rem 0.5rem;
      border-radius: 0.5rem;
    }

    .p-dock .p-dock-list-container .p-dock-list {
      outline: 0 none;
    }

    .p-dock .p-dock-item {
      padding: 0.5rem;
      border-radius: 6px;
    }

    .p-dock .p-dock-item.p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.15rem rgba(96, 165, 250, 0.2);
    }

    .p-dock .p-dock-link {
      width: 4rem;
      height: 4rem;
    }

    .p-dock.p-dock-top .p-dock-item-second-prev,
    .p-dock.p-dock-top .p-dock-item-second-next,
    .p-dock.p-dock-bottom .p-dock-item-second-prev,
    .p-dock.p-dock-bottom .p-dock-item-second-next {
      margin: 0 0.9rem;
    }

    .p-dock.p-dock-top .p-dock-item-prev,
    .p-dock.p-dock-top .p-dock-item-next,
    .p-dock.p-dock-bottom .p-dock-item-prev,
    .p-dock.p-dock-bottom .p-dock-item-next {
      margin: 0 1.3rem;
    }

    .p-dock.p-dock-top .p-dock-item-current,
    .p-dock.p-dock-bottom .p-dock-item-current {
      margin: 0 1.5rem;
    }

    .p-dock.p-dock-left .p-dock-item-second-prev,
    .p-dock.p-dock-left .p-dock-item-second-next,
    .p-dock.p-dock-right .p-dock-item-second-prev,
    .p-dock.p-dock-right .p-dock-item-second-next {
      margin: 0.9rem 0;
    }

    .p-dock.p-dock-left .p-dock-item-prev,
    .p-dock.p-dock-left .p-dock-item-next,
    .p-dock.p-dock-right .p-dock-item-prev,
    .p-dock.p-dock-right .p-dock-item-next {
      margin: 1.3rem 0;
    }

    .p-dock.p-dock-left .p-dock-item-current,
    .p-dock.p-dock-right .p-dock-item-current {
      margin: 1.5rem 0;
    }

    @media screen and (max-width: 960px) {

      .p-dock.p-dock-top .p-dock-list-container,
      .p-dock.p-dock-bottom .p-dock-list-container {
        overflow-x: auto;
        width: 100%;
      }

      .p-dock.p-dock-top .p-dock-list-container .p-dock-list,
      .p-dock.p-dock-bottom .p-dock-list-container .p-dock-list {
        margin: 0 auto;
      }

      .p-dock.p-dock-left .p-dock-list-container,
      .p-dock.p-dock-right .p-dock-list-container {
        overflow-y: auto;
        height: 100%;
      }

      .p-dock.p-dock-left .p-dock-list-container .p-dock-list,
      .p-dock.p-dock-right .p-dock-list-container .p-dock-list {
        margin: auto 0;
      }

      .p-dock .p-dock-list .p-dock-item {
        transform: none;
        margin: 0;
      }
    }

    .p-megamenu {
      padding: 0.5rem;
      background: #374151;
      color: rgba(255, 255, 255, 0.87);
      border: 1px solid #424b57;
      border-radius: 6px;
    }

    .p-megamenu .p-megamenu-root-list {
      outline: 0 none;
    }

    .p-megamenu .p-menuitem>.p-menuitem-content {
      color: rgba(255, 255, 255, 0.87);
      transition: box-shadow 0.2s;
      border-radius: 0;
    }

    .p-megamenu .p-menuitem>.p-menuitem-content .p-menuitem-link {
      color: rgba(255, 255, 255, 0.87);
      padding: 0.75rem 1.25rem;
      user-select: none;
    }

    .p-megamenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-megamenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-icon {
      color: rgba(255, 255, 255, 0.6);
      margin-right: 0.5rem;
    }

    .p-megamenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.6);
    }

    .p-megamenu .p-menuitem.p-highlight>.p-menuitem-content {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(96, 165, 250, 0.16);
    }

    .p-megamenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-megamenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-megamenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-megamenu .p-menuitem.p-highlight.p-focus>.p-menuitem-content {
      background: rgba(96, 165, 250, 0.24);
    }

    .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content {
      color: rgba(255, 255, 255, 0.87);
      background: #424b57;
    }

    .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(255, 255, 255, 0.03);
    }

    .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
    .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-megamenu .p-megamenu-panel {
      background: #374151;
      color: rgba(255, 255, 255, 0.87);
      border: 1px solid #424b57;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
      border-radius: 6px;
    }

    .p-megamenu .p-submenu-header {
      margin: 0;
      padding: 0.75rem 1.25rem;
      color: rgba(255, 255, 255, 0.87);
      background: #374151;
      font-weight: 700;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
    }

    .p-megamenu .p-submenu-list {
      padding: 0.25rem 0;
      width: 12.5rem;
    }

    .p-megamenu .p-submenu-list .p-menuitem-separator {
      border-top: 1px solid #424b57;
      margin: 0.25rem 0;
    }

    .p-megamenu.p-megamenu-vertical {
      width: 12.5rem;
      padding: 0.25rem 0;
    }

    .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list>.p-menuitem>.p-menuitem-content {
      color: rgba(255, 255, 255, 0.87);
      transition: box-shadow 0.2s;
      border-radius: 6px;
    }

    .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link {
      padding: 0.75rem 1.25rem;
      user-select: none;
    }

    .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-icon {
      color: rgba(255, 255, 255, 0.6);
      margin-right: 0.5rem;
    }

    .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.6);
      margin-left: 0.5rem;
    }

    .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list>.p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(255, 255, 255, 0.03);
    }

    .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list>.p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list>.p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
    .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list>.p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-menu {
      padding: 0.25rem 0;
      background: #374151;
      color: rgba(255, 255, 255, 0.87);
      border: 1px solid #424b57;
      border-radius: 6px;
      width: 12.5rem;
    }

    .p-menu .p-menuitem>.p-menuitem-content {
      color: rgba(255, 255, 255, 0.87);
      transition: box-shadow 0.2s;
      border-radius: 0;
    }

    .p-menu .p-menuitem>.p-menuitem-content .p-menuitem-link {
      color: rgba(255, 255, 255, 0.87);
      padding: 0.75rem 1.25rem;
      user-select: none;
    }

    .p-menu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-menu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-icon {
      color: rgba(255, 255, 255, 0.6);
      margin-right: 0.5rem;
    }

    .p-menu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.6);
    }

    .p-menu .p-menuitem.p-highlight>.p-menuitem-content {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(96, 165, 250, 0.16);
    }

    .p-menu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-menu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-menu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-menu .p-menuitem.p-highlight.p-focus>.p-menuitem-content {
      background: rgba(96, 165, 250, 0.24);
    }

    .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content {
      color: rgba(255, 255, 255, 0.87);
      background: #424b57;
    }

    .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(255, 255, 255, 0.03);
    }

    .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
    .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-menu.p-menu-overlay {
      background: #374151;
      border: 1px solid #424b57;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    }

    .p-menu .p-submenu-header {
      margin: 0;
      padding: 0.75rem 1.25rem;
      color: rgba(255, 255, 255, 0.87);
      background: #374151;
      font-weight: 700;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }

    .p-menu .p-menuitem-separator {
      border-top: 1px solid #424b57;
      margin: 0.25rem 0;
    }

    .p-menu .p-menuitem-badge {
      background: #60a5fa;
      color: #030712;
      font-size: 0.75rem;
      font-weight: 700;
      min-width: 1.5rem;
      height: 1.5rem;
      line-height: 1.5rem;
      border-radius: 6px;
      margin-left: 0.5rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    .p-menubar {
      padding: 0.5rem;
      background: #374151;
      color: rgba(255, 255, 255, 0.87);
      border: 1px solid #424b57;
      border-radius: 6px;
    }

    .p-menubar .p-menubar-root-list {
      outline: 0 none;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-content {
      color: rgba(255, 255, 255, 0.87);
      transition: box-shadow 0.2s;
      border-radius: 6px;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link {
      padding: 0.75rem 1.25rem;
      user-select: none;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-icon {
      color: rgba(255, 255, 255, 0.6);
      margin-right: 0.5rem;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.6);
      margin-left: 0.5rem;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(255, 255, 255, 0.03);
    }

    .p-menubar .p-menubar-root-list>.p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-menubar .p-menubar-root-list>.p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
    .p-menubar .p-menubar-root-list>.p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-menubar .p-menuitem>.p-menuitem-content {
      color: rgba(255, 255, 255, 0.87);
      transition: box-shadow 0.2s;
      border-radius: 0;
    }

    .p-menubar .p-menuitem>.p-menuitem-content .p-menuitem-link {
      color: rgba(255, 255, 255, 0.87);
      padding: 0.75rem 1.25rem;
      user-select: none;
    }

    .p-menubar .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-menubar .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-icon {
      color: rgba(255, 255, 255, 0.6);
      margin-right: 0.5rem;
    }

    .p-menubar .p-menuitem>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.6);
    }

    .p-menubar .p-menuitem.p-highlight>.p-menuitem-content {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(96, 165, 250, 0.16);
    }

    .p-menubar .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-menubar .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-menubar .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-menubar .p-menuitem.p-highlight.p-focus>.p-menuitem-content {
      background: rgba(96, 165, 250, 0.24);
    }

    .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content {
      color: rgba(255, 255, 255, 0.87);
      background: #424b57;
    }

    .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(255, 255, 255, 0.03);
    }

    .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
    .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-menubar .p-submenu-list {
      padding: 0.25rem 0;
      background: #374151;
      border: 1px solid #424b57;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
      width: 12.5rem;
      border-radius: 6px;
    }

    .p-menubar .p-submenu-list .p-menuitem-separator {
      border-top: 1px solid #424b57;
      margin: 0.25rem 0;
    }

    .p-menubar .p-submenu-list .p-submenu-icon {
      font-size: 0.875rem;
    }

    @media screen and (max-width: 960px) {
      .p-menubar {
        position: relative;
      }

      .p-menubar .p-menubar-button {
        display: flex;
        width: 2rem;
        height: 2rem;
        color: rgba(255, 255, 255, 0.6);
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      }

      .p-menubar .p-menubar-button:hover {
        color: rgba(255, 255, 255, 0.87);
        background: rgba(255, 255, 255, 0.03);
      }

      .p-menubar .p-menubar-button:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
      }

      .p-menubar .p-menubar-root-list {
        position: absolute;
        display: none;
        padding: 0.25rem 0;
        background: #374151;
        border: 1px solid #424b57;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
        width: 100%;
      }

      .p-menubar .p-menubar-root-list .p-menuitem-separator {
        border-top: 1px solid #424b57;
        margin: 0.25rem 0;
      }

      .p-menubar .p-menubar-root-list .p-submenu-icon {
        font-size: 0.875rem;
      }

      .p-menubar .p-menubar-root-list .p-menuitem {
        width: 100%;
        position: static;
      }

      .p-menubar .p-menubar-root-list .p-menuitem .p-menuitem-content .p-menuitem-link .p-submenu-icon {
        margin-left: auto;
        transition: transform 0.2s;
      }

      .p-menubar .p-menubar-root-list .p-menuitem.p-menuitem-active>.p-menuitem-content>.p-menuitem-link>.p-submenu-icon {
        transform: rotate(-180deg);
      }

      .p-menubar .p-menubar-root-list .p-submenu-list {
        width: 100%;
        position: static;
        box-shadow: none;
        border: 0 none;
      }

      .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
        transition: transform 0.2s;
        transform: rotate(90deg);
      }

      .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active>.p-menuitem-content>.p-menuitem-link>.p-submenu-icon {
        transform: rotate(-90deg);
      }

      .p-menubar .p-menubar-root-list .p-menuitem {
        width: 100%;
        position: static;
      }

      .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
        padding-left: 2.25rem;
      }

      .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
        padding-left: 3.75rem;
      }

      .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
        padding-left: 5.25rem;
      }

      .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
        padding-left: 6.75rem;
      }

      .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
        padding-left: 8.25rem;
      }

      .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
        display: flex;
        flex-direction: column;
        top: 100%;
        left: 0;
        z-index: 1;
      }
    }

    .p-panelmenu .p-panelmenu-header {
      outline: 0 none;
    }

    .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content {
      border: 1px solid #424b57;
      color: rgba(255, 255, 255, 0.87);
      background: #1f2937;
      border-radius: 6px;
      transition: box-shadow 0.2s;
    }

    .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action {
      color: rgba(255, 255, 255, 0.87);
      padding: 1.25rem;
      font-weight: 700;
    }

    .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action .p-submenu-icon {
      margin-right: 0.5rem;
    }

    .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action .p-menuitem-icon {
      margin-right: 0.5rem;
    }

    .p-panelmenu .p-panelmenu-header:not(.p-disabled):focus-visible .p-panelmenu-header-content {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover .p-panelmenu-header-content {
      background: rgba(255, 255, 255, 0.03);
      border-color: #424b57;
      color: rgba(255, 255, 255, 0.87);
    }

    .p-panelmenu .p-panelmenu-header:not(.p-disabled).p-highlight .p-panelmenu-header-content {
      background: #1f2937;
      border-color: #424b57;
      color: rgba(255, 255, 255, 0.87);
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      margin-bottom: 0;
    }

    .p-panelmenu .p-panelmenu-header:not(.p-disabled).p-highlight:hover .p-panelmenu-header-content {
      border-color: #424b57;
      background: rgba(255, 255, 255, 0.03);
      color: rgba(255, 255, 255, 0.87);
    }

    .p-panelmenu .p-panelmenu-content {
      padding: 0.25rem 0;
      border: 1px solid #424b57;
      background: #1f2937;
      color: rgba(255, 255, 255, 0.87);
      border-top: 0;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    .p-panelmenu .p-panelmenu-content .p-panelmenu-root-list {
      outline: 0 none;
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem>.p-menuitem-content {
      color: rgba(255, 255, 255, 0.87);
      transition: box-shadow 0.2s;
      border-radius: 0;
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem>.p-menuitem-content .p-menuitem-link {
      color: rgba(255, 255, 255, 0.87);
      padding: 0.75rem 1.25rem;
      user-select: none;
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-icon {
      color: rgba(255, 255, 255, 0.6);
      margin-right: 0.5rem;
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.6);
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight>.p-menuitem-content {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(96, 165, 250, 0.16);
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight.p-focus>.p-menuitem-content {
      background: rgba(96, 165, 250, 0.24);
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content {
      color: rgba(255, 255, 255, 0.87);
      background: #424b57;
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(255, 255, 255, 0.03);
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
    .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-content .p-menuitem-link .p-submenu-icon {
      margin-right: 0.5rem;
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem-separator {
      border-top: 1px solid #424b57;
      margin: 0.25rem 0;
    }

    .p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-list) {
      padding: 0 0 0 1rem;
    }

    .p-panelmenu .p-panelmenu-panel {
      margin-bottom: 4px;
    }

    .p-slidemenu {
      padding: 0.25rem 0;
      background: #374151;
      color: rgba(255, 255, 255, 0.87);
      border: 1px solid #424b57;
      border-radius: 6px;
      width: 12.5rem;
    }

    .p-slidemenu .p-slidemenu-root-list {
      outline: 0 none;
    }

    .p-slidemenu .p-submenu-list {
      outline: 0 none;
    }

    .p-slidemenu .p-menuitem>.p-menuitem-content {
      color: rgba(255, 255, 255, 0.87);
      transition: box-shadow 0.2s;
      border-radius: 0;
    }

    .p-slidemenu .p-menuitem>.p-menuitem-content .p-menuitem-link {
      color: rgba(255, 255, 255, 0.87);
      padding: 0.75rem 1.25rem;
      user-select: none;
    }

    .p-slidemenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-slidemenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-icon {
      color: rgba(255, 255, 255, 0.6);
      margin-right: 0.5rem;
    }

    .p-slidemenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.6);
    }

    .p-slidemenu .p-menuitem.p-highlight>.p-menuitem-content {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(96, 165, 250, 0.16);
    }

    .p-slidemenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-slidemenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-slidemenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-slidemenu .p-menuitem.p-highlight.p-focus>.p-menuitem-content {
      background: rgba(96, 165, 250, 0.24);
    }

    .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content {
      color: rgba(255, 255, 255, 0.87);
      background: #424b57;
    }

    .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(255, 255, 255, 0.03);
    }

    .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
    .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-slidemenu.p-slidemenu-overlay {
      background: #374151;
      border: 1px solid #424b57;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    }

    .p-slidemenu .p-slidemenu-list {
      padding: 0.25rem 0;
      background: #374151;
      border: 1px solid #424b57;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    }

    .p-slidemenu .p-menuitem-separator {
      border-top: 1px solid #424b57;
      margin: 0.25rem 0;
    }

    .p-slidemenu .p-slidemenu-icon {
      font-size: 0.875rem;
    }

    .p-slidemenu .p-icon {
      width: 0.875rem;
      height: 0.875rem;
    }

    .p-slidemenu .p-slidemenu-backward {
      padding: 0.75rem 1.25rem;
      color: rgba(255, 255, 255, 0.87);
    }

    .p-slidemenu .p-slidemenu-backward:not(.p-disabled):focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-slidemenu .p-menuitem-badge {
      background: #60a5fa;
      color: #030712;
      font-size: 0.75rem;
      font-weight: 700;
      min-width: 1.5rem;
      height: 1.5rem;
      line-height: 1.5rem;
      border-radius: 6px;
      margin-left: 0.5rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    .p-steps .p-steps-item .p-menuitem-link {
      background: transparent;
      transition: box-shadow 0.2s;
      border-radius: 6px;
      background: transparent;
    }

    .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
      color: rgba(255, 255, 255, 0.87);
      border: 0 none;
      background: transparent;
      min-width: 2rem;
      height: 2rem;
      line-height: 2rem;
      font-size: 1.143rem;
      z-index: 1;
      border-radius: 50%;
    }

    .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
      margin-top: 0.5rem;
      color: rgba(255, 255, 255, 0.6);
    }

    .p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-steps .p-steps-item.p-highlight .p-steps-number {
      background: rgba(96, 165, 250, 0.16);
      color: rgba(255, 255, 255, 0.87);
    }

    .p-steps .p-steps-item.p-highlight .p-steps-title {
      font-weight: 700;
      color: rgba(255, 255, 255, 0.87);
    }

    .p-steps .p-steps-item:before {
      content: " ";
      border-top: 1px solid #424b57;
      width: 100%;
      top: 50%;
      left: 0;
      display: block;
      position: absolute;
      margin-top: -1rem;
    }

    .p-tabmenu .p-tabmenu-nav {
      background: transparent;
      border: 1px solid #424b57;
      border-width: 0 0 2px 0;
    }

    .p-tabmenu .p-tabmenu-nav .p-menuitem-badge {
      background: #60a5fa;
      color: #030712;
      font-size: 0.75rem;
      font-weight: 700;
      min-width: 1.5rem;
      height: 1.5rem;
      line-height: 1.5rem;
      border-radius: 6px;
      margin-left: 0.5rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
      margin-right: 0;
    }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
      border: solid #424b57;
      border-width: 0 0 2px 0;
      border-color: transparent transparent #424b57 transparent;
      background: #1f2937;
      color: rgba(255, 255, 255, 0.6);
      padding: 1.25rem;
      font-weight: 700;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      transition: box-shadow 0.2s;
      margin: 0 0 -2px 0;
    }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
      margin-right: 0.5rem;
    }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
      background: #1f2937;
      border-color: #60a5fa;
      color: rgba(255, 255, 255, 0.87);
    }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
      background: #1f2937;
      border-color: #60a5fa;
      color: #60a5fa;
    }

    .p-tabmenu .p-tabmenu-left-icon {
      margin-right: 0.5rem;
    }

    .p-tabmenu .p-tabmenu-right-icon {
      margin-left: 0.5rem;
    }

    .p-tabmenu .p-tabmenu-nav-btn.p-link {
      background: #1f2937;
      color: #60a5fa;
      width: 3rem;
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
      border-radius: 0;
    }

    .p-tabmenu .p-tabmenu-nav-btn.p-link:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-tieredmenu {
      padding: 0.25rem 0;
      background: #374151;
      color: rgba(255, 255, 255, 0.87);
      border: 1px solid #424b57;
      border-radius: 6px;
      width: 12.5rem;
    }

    .p-tieredmenu.p-tieredmenu-overlay {
      background: #374151;
      border: 1px solid #424b57;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    }

    .p-tieredmenu .p-tieredmenu-root-list {
      outline: 0 none;
    }

    .p-tieredmenu .p-submenu-list {
      padding: 0.25rem 0;
      background: #374151;
      border: 1px solid #424b57;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
      border-radius: 6px;
    }

    .p-tieredmenu .p-menuitem>.p-menuitem-content {
      color: rgba(255, 255, 255, 0.87);
      transition: box-shadow 0.2s;
      border-radius: 0;
    }

    .p-tieredmenu .p-menuitem>.p-menuitem-content .p-menuitem-link {
      color: rgba(255, 255, 255, 0.87);
      padding: 0.75rem 1.25rem;
      user-select: none;
    }

    .p-tieredmenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-tieredmenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-icon {
      color: rgba(255, 255, 255, 0.6);
      margin-right: 0.5rem;
    }

    .p-tieredmenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.6);
    }

    .p-tieredmenu .p-menuitem.p-highlight>.p-menuitem-content {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(96, 165, 250, 0.16);
    }

    .p-tieredmenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-tieredmenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-tieredmenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-tieredmenu .p-menuitem.p-highlight.p-focus>.p-menuitem-content {
      background: rgba(96, 165, 250, 0.24);
    }

    .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content {
      color: rgba(255, 255, 255, 0.87);
      background: #424b57;
    }

    .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
      color: rgba(255, 255, 255, 0.87);
      background: rgba(255, 255, 255, 0.03);
    }

    .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
    .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.87);
    }

    .p-tieredmenu .p-menuitem-separator {
      border-top: 1px solid #424b57;
      margin: 0.25rem 0;
    }

    .p-tieredmenu .p-submenu-icon {
      font-size: 0.875rem;
    }

    .p-tieredmenu .p-submenu-icon.p-icon {
      width: 0.875rem;
      height: 0.875rem;
    }

    .p-inline-message {
      padding: 0.75rem 0.75rem;
      margin: 0;
      border-radius: 6px;
    }

    .p-inline-message.p-inline-message-info {
      background: rgba(59, 130, 246, 0.2);
      border: solid #3b82f6;
      border-width: 1px;
      color: #93c5fd;
    }

    .p-inline-message.p-inline-message-info .p-inline-message-icon {
      color: #93c5fd;
    }

    .p-inline-message.p-inline-message-success {
      background: rgba(16, 185, 129, 0.2);
      border: solid #10b981;
      border-width: 1px;
      color: #6ee7b7;
    }

    .p-inline-message.p-inline-message-success .p-inline-message-icon {
      color: #6ee7b7;
    }

    .p-inline-message.p-inline-message-warn {
      background: rgba(234, 179, 8, 0.2);
      border: solid #eab308;
      border-width: 1px;
      color: #fde047;
    }

    .p-inline-message.p-inline-message-warn .p-inline-message-icon {
      color: #fde047;
    }

    .p-inline-message.p-inline-message-error {
      background: rgba(239, 68, 68, 0.2);
      border: solid #ef4444;
      border-width: 1px;
      color: #fca5a5;
    }

    .p-inline-message.p-inline-message-error .p-inline-message-icon {
      color: #fca5a5;
    }

    .p-inline-message .p-inline-message-icon {
      font-size: 1rem;
      margin-right: 0.5rem;
    }

    .p-inline-message .p-icon {
      width: 1rem;
      height: 1rem;
    }

    .p-inline-message .p-inline-message-text {
      font-size: 1rem;
    }

    .p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
      margin-right: 0;
    }

    .p-message {
      margin: 1rem 0;
      border-radius: 6px;
    }

    .p-message .p-message-wrapper {
      padding: 1.25rem 1.75rem;
    }

    .p-message .p-message-close {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background: transparent;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

    .p-message .p-message-close:hover {
      background: rgba(255, 255, 255, 0.5);
    }

    .p-message .p-message-close:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-message.p-message-info {
      background: rgba(59, 130, 246, 0.2);
      border: solid #3b82f6;
      border-width: 0 0 0 6px;
      color: #93c5fd;
    }

    .p-message.p-message-info .p-message-icon {
      color: #93c5fd;
    }

    .p-message.p-message-info .p-message-close {
      color: #93c5fd;
    }

    .p-message.p-message-success {
      background: rgba(16, 185, 129, 0.2);
      border: solid #10b981;
      border-width: 0 0 0 6px;
      color: #6ee7b7;
    }

    .p-message.p-message-success .p-message-icon {
      color: #6ee7b7;
    }

    .p-message.p-message-success .p-message-close {
      color: #6ee7b7;
    }

    .p-message.p-message-warn {
      background: rgba(234, 179, 8, 0.2);
      border: solid #eab308;
      border-width: 0 0 0 6px;
      color: #fde047;
    }

    .p-message.p-message-warn .p-message-icon {
      color: #fde047;
    }

    .p-message.p-message-warn .p-message-close {
      color: #fde047;
    }

    .p-message.p-message-error {
      background: rgba(239, 68, 68, 0.2);
      border: solid #ef4444;
      border-width: 0 0 0 6px;
      color: #fca5a5;
    }

    .p-message.p-message-error .p-message-icon {
      color: #fca5a5;
    }

    .p-message.p-message-error .p-message-close {
      color: #fca5a5;
    }

    .p-message .p-message-text {
      font-size: 1rem;
      font-weight: 500;
    }

    .p-message .p-message-icon {
      font-size: 1.5rem;
      margin-right: 0.5rem;
    }

    .p-message .p-icon {
      width: 1.5rem;
      height: 1.5rem;
    }

    .p-message .p-message-summary {
      font-weight: 700;
    }

    .p-message .p-message-detail {
      margin-left: 0.5rem;
    }

    .p-message.p-message-secondary {
      background: #94a3b8;
      border: solid #94a3b8;
      border-width: 0 0 0 6px;
      color: #020617;
    }

    .p-message.p-message-secondary .p-message-icon {
      color: #020617;
    }

    .p-message.p-message-secondary .p-message-close {
      color: #020617;
    }

    .p-message.p-message-contrast {
      background: #ffffff;
      border: solid #ffffff;
      border-width: 0 0 0 6px;
      color: #111827;
    }

    .p-message.p-message-contrast .p-message-icon {
      color: #111827;
    }

    .p-message.p-message-contrast .p-message-close {
      color: #111827;
    }

    .p-toast {
      opacity: 1;
    }

    .p-toast .p-toast-message {
      margin: 0 0 1rem 0;
      box-shadow: none;
      border-radius: 6px;
    }

    .p-toast .p-toast-message .p-toast-message-content {
      padding: 1rem;
      border-width: 0 0 0 6px;
    }

    .p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
      margin: 0 0 0 1rem;
    }

    .p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
      font-size: 2rem;
    }

    .p-toast .p-toast-message .p-toast-message-content .p-icon:not(.p-toast-icon-close-icon) {
      width: 2rem;
      height: 2rem;
    }

    .p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
      font-weight: 700;
    }

    .p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
      margin: 0.5rem 0 0 0;
    }

    .p-toast .p-toast-message .p-toast-icon-close {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background: transparent;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

    .p-toast .p-toast-message .p-toast-icon-close:hover {
      background: rgba(255, 255, 255, 0.5);
    }

    .p-toast .p-toast-message .p-toast-icon-close:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-toast .p-toast-message.p-toast-message-info {
      background: rgba(59, 130, 246, 0.2);
      border: solid #3b82f6;
      border-width: 0 0 0 6px;
      color: #93c5fd;
    }

    .p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
    .p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
      color: #93c5fd;
    }

    .p-toast .p-toast-message.p-toast-message-success {
      background: rgba(16, 185, 129, 0.2);
      border: solid #10b981;
      border-width: 0 0 0 6px;
      color: #6ee7b7;
    }

    .p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
    .p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
      color: #6ee7b7;
    }

    .p-toast .p-toast-message.p-toast-message-warn {
      background: rgba(234, 179, 8, 0.2);
      border: solid #eab308;
      border-width: 0 0 0 6px;
      color: #fde047;
    }

    .p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
    .p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
      color: #fde047;
    }

    .p-toast .p-toast-message.p-toast-message-error {
      background: rgba(239, 68, 68, 0.2);
      border: solid #ef4444;
      border-width: 0 0 0 6px;
      color: #fca5a5;
    }

    .p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
    .p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
      color: #fca5a5;
    }

    .p-toast .p-toast-message.p-toast-message-secondary {
      background: #94a3b8;
      border: solid #94a3b8;
      border-width: 0 0 0 6px;
      color: #020617;
    }

    .p-toast .p-toast-message.p-toast-message-secondary .p-toast-message-icon,
    .p-toast .p-toast-message.p-toast-message-secondary .p-toast-icon-close {
      color: #020617;
    }

    .p-toast .p-toast-message.p-toast-message-contrast {
      background: #ffffff;
      border: solid #ffffff;
      border-width: 0 0 0 6px;
      color: #111827;
    }

    .p-toast .p-toast-message.p-toast-message-contrast .p-toast-message-icon,
    .p-toast .p-toast-message.p-toast-message-contrast .p-toast-icon-close {
      color: #111827;
    }

    .p-galleria .p-galleria-close {
      margin: 0.5rem;
      background: transparent;
      color: #f8f9fa;
      width: 4rem;
      height: 4rem;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      border-radius: 50%;
    }

    .p-galleria .p-galleria-close .p-galleria-close-icon {
      font-size: 2rem;
    }

    .p-galleria .p-galleria-close .p-icon-wrapper .p-icon {
      width: 2rem;
      height: 2rem;
    }

    .p-galleria .p-galleria-close:hover {
      background: rgba(255, 255, 255, 0.1);
      color: #f8f9fa;
    }

    .p-galleria .p-galleria-item-nav {
      background: transparent;
      color: #f8f9fa;
      width: 4rem;
      height: 4rem;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      border-radius: 6px;
      margin: 0 0.5rem;
    }

    .p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
    .p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
      font-size: 2rem;
    }

    .p-galleria .p-galleria-item-nav .p-icon-wrapper .p-icon {
      width: 2rem;
      height: 2rem;
    }

    .p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
      background: rgba(255, 255, 255, 0.1);
      color: #f8f9fa;
    }

    .p-galleria .p-galleria-caption {
      background: rgba(0, 0, 0, 0.5);
      color: #f8f9fa;
      padding: 1rem;
    }

    .p-galleria .p-galleria-indicators {
      padding: 1rem;
    }

    .p-galleria .p-galleria-indicators .p-galleria-indicator button {
      background-color: #424b57;
      width: 1rem;
      height: 1rem;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      border-radius: 50%;
    }

    .p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
      background: rgba(255, 255, 255, 0.1);
    }

    .p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
      background: rgba(96, 165, 250, 0.16);
      color: rgba(255, 255, 255, 0.87);
    }

    .p-galleria.p-galleria-indicators-bottom .p-galleria-indicator,
    .p-galleria.p-galleria-indicators-top .p-galleria-indicator {
      margin-right: 0.5rem;
    }

    .p-galleria.p-galleria-indicators-left .p-galleria-indicator,
    .p-galleria.p-galleria-indicators-right .p-galleria-indicator {
      margin-bottom: 0.5rem;
    }

    .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
      background: rgba(0, 0, 0, 0.5);
    }

    .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
      background: rgba(255, 255, 255, 0.4);
    }

    .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover {
      background: rgba(255, 255, 255, 0.6);
    }

    .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
      background: rgba(96, 165, 250, 0.16);
      color: rgba(255, 255, 255, 0.87);
    }

    .p-galleria .p-galleria-thumbnail-container {
      background: rgba(0, 0, 0, 0.9);
      padding: 1rem 0.25rem;
    }

    .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
    .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
      margin: 0.5rem;
      background-color: transparent;
      color: #f8f9fa;
      width: 2rem;
      height: 2rem;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      border-radius: 50%;
    }

    .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
    .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
      background: rgba(255, 255, 255, 0.1);
      color: #f8f9fa;
    }

    .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-galleria-mask {
      --maskbg: rgba(0, 0, 0, 0.9);
    }

    .p-image-mask {
      --maskbg: rgba(0, 0, 0, 0.9);
    }

    .p-image-preview-indicator {
      background-color: transparent;
      color: #f8f9fa;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

    .p-image-preview-container:hover>.p-image-preview-indicator {
      background-color: rgba(0, 0, 0, 0.5);
    }

    .p-image-toolbar {
      padding: 1rem;
    }

    .p-image-action.p-link {
      color: #f8f9fa;
      background-color: transparent;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      margin-right: 0.5rem;
    }

    .p-image-action.p-link:last-child {
      margin-right: 0;
    }

    .p-image-action.p-link:hover {
      color: #f8f9fa;
      background-color: rgba(255, 255, 255, 0.1);
    }

    .p-image-action.p-link i {
      font-size: 1.5rem;
    }

    .p-image-action.p-link .p-icon {
      width: 1.5rem;
      height: 1.5rem;
    }

    .p-avatar {
      background-color: #424b57;
      border-radius: 6px;
    }

    .p-avatar.p-avatar-lg {
      width: 3rem;
      height: 3rem;
      font-size: 1.5rem;
    }

    .p-avatar.p-avatar-lg .p-avatar-icon {
      font-size: 1.5rem;
    }

    .p-avatar.p-avatar-xl {
      width: 4rem;
      height: 4rem;
      font-size: 2rem;
    }

    .p-avatar.p-avatar-xl .p-avatar-icon {
      font-size: 2rem;
    }

    .p-avatar-group .p-avatar {
      border: 2px solid #1f2937;
    }

    .p-badge {
      background: #60a5fa;
      color: #030712;
      font-size: 0.75rem;
      font-weight: 700;
      min-width: 1.5rem;
      height: 1.5rem;
      line-height: 1.5rem;
    }

    .p-badge.p-badge-secondary {
      background-color: #94a3b8;
      color: #020617;
    }

    .p-badge.p-badge-contrast {
      background-color: #ffffff;
      color: #111827;
    }

    .p-badge.p-badge-success {
      background-color: #4ade80;
      color: #052e16;
    }

    .p-badge.p-badge-info {
      background-color: #38bdf8;
      color: #082f49;
    }

    .p-badge.p-badge-warning {
      background-color: #fb923c;
      color: #431407;
    }

    .p-badge.p-badge-danger {
      background-color: #f87171;
      color: #450a0a;
    }

    .p-badge.p-badge-lg {
      font-size: 1.125rem;
      min-width: 2.25rem;
      height: 2.25rem;
      line-height: 2.25rem;
    }

    .p-badge.p-badge-xl {
      font-size: 1.5rem;
      min-width: 3rem;
      height: 3rem;
      line-height: 3rem;
    }

    .p-chip {
      background-color: #424b57;
      color: rgba(255, 255, 255, 0.87);
      border-radius: 16px;
      padding: 0 0.75rem;
    }

    .p-chip .p-chip-text {
      line-height: 1.5;
      margin-top: 0.375rem;
      margin-bottom: 0.375rem;
    }

    .p-chip .p-chip-icon {
      margin-right: 0.5rem;
    }

    .p-chip .pi-chip-remove-icon {
      margin-left: 0.5rem;
    }

    .p-chip img {
      width: 2.25rem;
      height: 2.25rem;
      margin-left: -0.75rem;
      margin-right: 0.5rem;
    }

    .p-chip .pi-chip-remove-icon {
      border-radius: 6px;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

    .p-chip .pi-chip-remove-icon:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-chip .pi-chip-remove-icon:focus {
      outline: 0 none;
    }

    .p-inplace .p-inplace-display {
      padding: 0.75rem 0.75rem;
      border-radius: 6px;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    }

    .p-inplace .p-inplace-display:not(.p-disabled):hover {
      background: rgba(255, 255, 255, 0.03);
      color: rgba(255, 255, 255, 0.87);
    }

    .p-inplace .p-inplace-display:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(96, 165, 250, 0.2);
    }

    .p-metergroup {
      display: flex;
    }

    .p-metergroup-meters {
      display: flex;
    }

    .p-metergroup-vertical .p-metergroup-meters {
      flex-direction: column;
    }

    .p-metergroup-labels {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .p-metergroup-vertical .p-metergroup-labels {
      align-items: start;
    }

    .p-metergroup-labels-vertical {
      flex-direction: column;
    }

    .p-metergroup-label {
      display: inline-flex;
      align-items: center;
    }

    .p-metergroup-label-marker {
      display: inline-flex;
    }

    .p-metergroup {
      gap: 1rem;
    }

    .p-metergroup .p-metergroup-meters {
      background: #424b57;
      border-radius: 6px;
    }

    .p-metergroup .p-metergroup-meter {
      border: 0 none;
      background: #60a5fa;
    }

    .p-metergroup .p-metergroup-labels .p-metergroup-label {
      gap: 0.5rem;
    }

    .p-metergroup .p-metergroup-labels .p-metergroup-label-marker {
      background: #60a5fa;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 100%;
    }

    .p-metergroup .p-metergroup-labels .p-metergroup-label-icon {
      width: 1rem;
      height: 1rem;
    }

    .p-metergroup .p-metergroup-labels.p-metergroup-labels-vertical {
      gap: 0.5rem;
    }

    .p-metergroup .p-metergroup-labels.p-metergroup-labels-horizontal {
      gap: 1rem;
    }

    .p-metergroup.p-metergroup-horizontal {
      flex-direction: column;
    }

    .p-metergroup.p-metergroup-horizontal .p-metergroup-meters {
      height: 0.5rem;
    }

    .p-metergroup.p-metergroup-horizontal .p-metergroup-meter:first-of-type {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    .p-metergroup.p-metergroup-horizontal .p-metergroup-meter:last-of-type {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    .p-metergroup.p-metergroup-vertical {
      flex-direction: row;
    }

    .p-metergroup.p-metergroup-vertical .p-metergroup-meters {
      width: 0.5rem;
      height: 100%;
    }

    .p-metergroup.p-metergroup-vertical .p-metergroup-meter:first-of-type {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    .p-metergroup.p-metergroup-vertical .p-metergroup-meter:last-of-type {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    .p-progressbar {
      border: 0 none;
      height: 1.5rem;
      background: #424b57;
      border-radius: 6px;
    }

    .p-progressbar .p-progressbar-value {
      border: 0 none;
      margin: 0;
      background: #60a5fa;
    }

    .p-progressbar .p-progressbar-label {
      color: #030712;
      line-height: 1.5rem;
    }

    .p-scrolltop {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

    .p-scrolltop.p-link {
      background: rgba(96, 165, 250, 0.16);
    }

    .p-scrolltop.p-link:hover {
      background: rgba(96, 165, 250, 0.3616);
    }

    .p-scrolltop .p-scrolltop-icon {
      font-size: 1.5rem;
      color: rgba(255, 255, 255, 0.87);
    }

    .p-scrolltop .p-icon {
      width: 1.5rem;
      height: 1.5rem;
    }

    .p-skeleton {
      background-color: rgba(255, 255, 255, 0.06);
      border-radius: 6px;
    }

    .p-skeleton:after {
      background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0));
    }

    .p-tag {
      background: #60a5fa;
      color: #030712;
      font-size: 0.75rem;
      font-weight: 700;
      padding: 0.25rem 0.4rem;
      border-radius: 6px;
    }

    .p-tag.p-tag-success {
      background-color: #4ade80;
      color: #052e16;
    }

    .p-tag.p-tag-info {
      background-color: #38bdf8;
      color: #082f49;
    }

    .p-tag.p-tag-warning {
      background-color: #fb923c;
      color: #431407;
    }

    .p-tag.p-tag-danger {
      background-color: #f87171;
      color: #450a0a;
    }

    .p-tag .p-tag-icon {
      margin-right: 0.25rem;
      font-size: 0.75rem;
    }

    .p-tag .p-icon {
      width: 0.75rem;
      height: 0.75rem;
    }

    .p-tag.p-tag-secondary {
      background-color: #94a3b8;
      color: #020617;
    }

    .p-tag.p-tag-contrast {
      background-color: #ffffff;
      color: #111827;
    }

    .p-terminal {
      background: #1f2937;
      color: rgba(255, 255, 255, 0.87);
      border: 1px solid #424b57;
      padding: 1.25rem;
    }

    .p-terminal .p-terminal-input {
      font-family: var(--font-family);
      font-feature-settings: var(--font-feature-settings, normal);
      font-size: 1rem;
    }
  }

  @layer primeng {
    .p-button-label {
      font-weight: 700;
    }

    .p-selectbutton>.p-button,
    .p-togglebutton.p-button {
      transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    }

    .p-accordion .p-accordion-header .p-accordion-header-link {
      transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    }

    .p-tabview .p-tabview-nav li .p-tabview-nav-link {
      transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
      transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    }

    .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
      background-color: #60a5fa;
    }

    .p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
      background-color: #60a5fa;
    }

    .p-button:focus {
      box-shadow: 0 0 0 2px #1c2127, 0 0 0 4px rgba(96, 165, 250, 0.7), 0 1px 2px 0 rgba(0, 0, 0, 0);
    }

    .p-button.p-button-secondary:enabled:focus {
      box-shadow: 0 0 0 2px #1c2127, 0 0 0 4px rgba(148, 163, 184, 0.7), 0 1px 2px 0 rgba(0, 0, 0, 0);
    }

    .p-button.p-button-success:enabled:focus {
      box-shadow: 0 0 0 2px #1c2127, 0 0 0 4px rgba(74, 222, 128, 0.7), 0 1px 2px 0 rgba(0, 0, 0, 0);
    }

    .p-button.p-button-info:enabled:focus {
      box-shadow: 0 0 0 2px #1c2127, 0 0 0 4px rgba(56, 189, 248, 0.7), 0 1px 2px 0 rgba(0, 0, 0, 0);
    }

    .p-button.p-button-warning:enabled:focus {
      box-shadow: 0 0 0 2px #1c2127, 0 0 0 4px rgba(251, 146, 60, 0.7), 0 1px 2px 0 rgba(0, 0, 0, 0);
    }

    .p-button.p-button-help:enabled:focus {
      box-shadow: 0 0 0 2px #1c2127, 0 0 0 4px rgba(192, 132, 252, 0.7), 0 1px 2px 0 rgba(0, 0, 0, 0);
    }

    .p-button.p-button-danger:enabled:focus {
      box-shadow: 0 0 0 2px #1c2127, 0 0 0 4px rgba(248, 113, 113, 0.7), 0 1px 2px 0 rgba(0, 0, 0, 0);
    }

    .p-datatable .p-datatable-tbody>tr.p-datatable-dragpoint-top>td {
      box-shadow: inset 0 2px 0 0 #60a5fa;
    }

    .p-datatable .p-datatable-tbody>tr.p-datatable-dragpoint-bottom>td {
      box-shadow: inset 0 -2px 0 0 #60a5fa;
    }

    .p-datatable .p-datatable-tbody>tr.p-datatable-dragpoint-top>td {
      box-shadow: inset 0 2px 0 0 #60a5fa;
    }

    .p-datatable .p-datatable-tbody>tr.p-datatable-dragpoint-bottom>td {
      box-shadow: inset 0 -2px 0 0 #60a5fa;
    }

    .p-speeddial-item.p-focus>.p-speeddial-action {
      box-shadow: 0 0 0 2px #1c2127, 0 0 0 4px rgba(96, 165, 250, 0.7), 0 1px 2px 0 rgba(0, 0, 0, 0);
    }

    .p-toast-message {
      backdrop-filter: blur(10px);
    }

    .p-message .p-message-close:hover {
      background: rgba(255, 255, 255, 0.1);
    }

    .p-toast .p-toast-message .p-toast-icon-close:hover {
      background: rgba(255, 255, 255, 0.1);
    }

    .p-inline-message-text {
      font-weight: 500;
    }

    .p-picklist-buttons .p-button,
    .p-orderlist-controls .p-button {
      transition: opacity 0.2s, background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    }

    .p-steps .p-steps-item.p-highlight .p-steps-number {
      background: #60a5fa;
      color: #030712;
    }
  }

}