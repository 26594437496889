@keyframes loadingAnimation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

body {
  &:not(.uw-ready) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(-45deg, #e0e0eb, #9999aa, #666677, #14141f);
    background-size: 400% 400%;
    animation: loadingAnimation 8s ease-in-out infinite;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: radial-gradient(
        circle at center,
        rgba(255, 255, 255, 0.25) 0%,
        rgba(255, 255, 255, 0) 70%
      );
      pointer-events: none;
    }
  }

  &.uw-ready {
    background-color: var(--color-surface-body);
    @apply tw-bg-gradient-to-tl tw-from-[var(--color-body-gradient-from)] tw-via-[var(--color-body-gradient-via)] tw-to-[var(--color-body-gradient-to)];
  }
}

.uw-page-fold {
  @apply tw-min-h-[var(--fold-height)];
}

.uw-h-page-fold {
  @apply tw-h-[var(--fold-height)];
}

.uw-page-container {
  @apply tw-flex tw-flex-col uw-page-fold;
}

.uw-fields-container,
.uw-fields-container .p-accordion-content,
.uw-fields-container .p-fieldset-content {
  @apply tw-flex tw-flex-col tw-gap-10 tw-pt-6;
}

.uw-form-vertical {
  @apply tw-flex tw-flex-col tw-gap-4 tw-py-6;
}

.uw-message {
  @apply tw-p-1 tw-rounded tw-opacity-90;

  &.uw-message-info {
    @apply surface-main;
  }

  &.uw-message-success {
    @apply surface-success;
  }

  &.uw-message-warning {
    @apply surface-warning;
  }

  &.uw-message-danger {
    @apply surface-danger;
  }
}

.uw-tag {
  @apply tw-inline-flex tw-gap-1 tw-items-center tw-p-1 tw-rounded tw-text-xs tw-font-medium;

 
  &.uw-tag-contrast {
    @apply surface-light;
  }

  &.uw-tag-secondary {
    @apply surface-secondary;
  }

  &.uw-tag-info {
    @apply surface-info;
  }

  &.uw-tag-success {
    @apply surface-success;
  }

  &.uw-tag-warning {
    @apply surface-warning;
  }

  &.uw-tag-danger {
    @apply surface-danger;
  }
}

.mat-typography .uw-text-content {
  @apply tw-text-base;

  h1 {
    @apply tw-text-4xl tw-font-semibold tw-mb-4;
  }

  h2 {
    @apply tw-text-2xl tw-font-semibold tw-mb-3;
  }

  h3 {
    @apply tw-text-2xl tw-font-medium tw-mb-2;
  }

  h4 {
    @apply tw-text-xl tw-font-medium tw-mb-2;
  }

  strong {
    @apply tw-font-medium;
  }

  ul {
    @apply tw-list-disc tw-pl-6;
  }

  hr {
    @apply tw-border-0 tw-border-t tw-border-solid tw-my-4;
  }
}

.uw-sticky-bottom-toolbar {
  @apply tw-sticky tw-bottom-0 tw-z-10 tw-flex tw-items-center tw-flex-wrap tw-gap-2 tw-p-2 tw-rounded-t-lg surface-main tw-backdrop-blur-sm;
}

.uw-list-card-container {
  @apply tw-grid tw-grid-cols-1 md:tw-grid-cols-2 2xl:tw-grid-cols-3 tw-gap-6;

  .uw-list-card {
    @apply tw-flex tw-flex-col tw-h-full tw-gap-4 surface-main hover:surface-main-lighter tw-p-4 tw-rounded-xl;
  }
}

// narrow scroll bars
::-webkit-scrollbar {
  width: 8px;
  /* Width of the vertical scrollbar */
  height: 8px;
  /* Height of the horizontal scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: #888;
  /* Color of the scrollbar thumb */
  border-radius: 4px;
  /* Rounded corners for the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Color of the scrollbar thumb on hover */
}


.p-stepper-panels {
  @apply surface-main tw-rounded-xl tw-max-w-5xl tw-mx-auto;

  p-steppercontent>div {
    @apply tw-flex tw-flex-col tw-gap-2 tw-min-h-96 tw-flex-grow;

    .stepper-footer {
      @apply tw-flex tw-justify-between tw-mt-auto;
    }
  }
}

app-onboarding {
  @apply tw-block;


}

mat-accordion.uw-accordion-form {
  .mat-expansion-panel-body {
    @apply tw-flex tw-flex-col tw-gap-2 tw-items-start;

    .p-float-label {
      @apply tw-mt-6;
    }
  }
}